import styled, { keyframes } from 'styled-components'

const fadeEaseInOut = keyframes`
 0% {
    transform: translate(50%);
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    position: absolute;
    left: 0px;
 }
 100% { 
    opacity: 1;
    transform: translateX(0px);
    visibility: visible;
    z-index: 1;
    position: absolute;
    left: 0px;
 }
`

export const ContainerLogin = styled.div`
   @media(min-width: 1365px) {
      animation-name: ${fadeEaseInOut};
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;
   }
   @media (max-width: 1366px) {
      &&& .form-box-decoration {
         display: block;
      }
   }
   @media (max-width: 1280px) {
      &&& .form-box-decoration {
         display: none;
      }
   }
   img.right {
      position: absolute;
      left: 360px;
      top: -70px;
      max-width: 160px;
   }
   img.left {
      position: absolute;
      left: -60px;
      top: -80px;
      max-width: 140px;
   }
   p.form-text {
      text-align: center;
   }
   button.secondary {
      background-color: #c246e0;
   }
   .tab-switch {
      position: absolute;
      max-width: 60%;
      .false {
         color: #888;
         background-color: #c246e030;
      }
      .active {
         color: #fff;
         background-color: #c246e0;
      }
   }
   & > img.logo-home {
      display: none;
   }
   @media (max-width: 1280px) {
      & > img.logo-home {
         display: block;
         max-width: 120px;
         position: absolute;
         left: 50%;
         margin-left: -60px;
         margin-top: -60px;
      }
   }
`

export const ContainerRegister = styled.div`
   height: auto;
   max-height: auto;
   img.right {
      position: absolute;
      left: 360px;
      top: -25px;
      max-width: 160px;
   }
   img.left {
      position: absolute;
      left: -60px;
      top: -30px;
      max-width: 140px;
   }
   .tab-switch {
      position: absolute;
      max-width: 60%;
      margin-left: 16px;
      .false {
         color: #888;
         background-color: #4444ee30;
      }
      .active {
         color: #fff;
         background-color: #4444e0;
      }
   }

   h2 {
      padding-top: 60px;
      margin-bottom: 16px;

   }

   form {
      margin-top: 0 !important;
   }

   button.button.primary {
      background-color: #c246e0;
   }

   @media(min-width: 1365px) {   
      height: 100%;
      max-height: 735px;

      animation-name: ${fadeEaseInOut};
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;
   }
`

export const ContainerHolder = styled.div`
   width: 100%;
   height: 100%;
   overflow: auto;

   @media(min-width: 1366px) {
      width: 100%;
      height: calc(100% - 76px);
      overflow-y: auto;
      overflow-x: visible;
   }
`

export const Line = styled.div`
   align-items: center;
   display: flex;
   justify-content: center;
   margin-top: 40px;
   margin-bottom: 16px;
`
export const Landing = styled.div`
   background-position: -10% center;
   .landing-form {
      margin-top: 80px;
      right: 80px;
   }
   @media (min-width: 1440px) {
      background-size: contain;
      background-position: 0 10%;
   }
   @media (max-width: 1365px) {
      .form-box {
         position: absolute;
         top: 10%;
         right: -600px;
      }
   }
   @media (max-width: 1280px) {
      &.landing {
         height: unset;
      }
      .form-box {
         position: unset;
         top: unset;
         right: unset;
      }
   }
   @media(max-width: 500px) {
      .form-box {
         padding-top: 64px;
      }
   }
`