import heroi from '../../../assets/img/mecanicas/step-2/heroi-laranja.png'
import logoHeroi from '../../../assets/img/mecanicas/step-2/logo-heroi-laranja.png'
import logoMondelez from '../../../assets/img/mecanicas/step-2/logo-mondelez.png'
import titulo from '../../../assets/img/mecanicas/step-2/titulo-fdf.png'
import titulo2 from '../../../assets/img/mecanicas/step-2/titulo-laranja.png'
import * as S from './styled'

function MecanicaFDF() {
  return <S.Container>
    <S.TitleContainer>
      <S.TitleLogo>
        <img src={logoHeroi} alt="" />
      </S.TitleLogo>
      <S.Title>
        <img src={titulo} alt="" />
      </S.Title>
    </S.TitleContainer>
    <S.CardContainer>
      <S.Card>
        <S.CardTitle>
          <h2>KPI´s</h2>
        </S.CardTitle>
        <hr />
        <S.KPIContainer style={{ justifyContent: 'center' }}>
          <S.KPIText>
            <p><strong>Sell In*</strong></p>
            <p>Soma cota total Mdlz<br />Target: Cota BI</p>
          </S.KPIText>
        </S.KPIContainer>
        <hr />
        <S.KPIContainer>
          <S.KPIText>
            <h3>Total marcas</h3>
            <S.Logos>
              <div>
                <img src={logoMondelez} alt="" style={{ maxWidth: "84px" }} />
              </div>
            </S.Logos>
          </S.KPIText>
          <S.InfoText>
            100 a 100,9% = 10 escudos <br />
            101 a 105,09% = 30 escudos  <br />
            ≥106% = 60 escudos  <br />
          </S.InfoText>
        </S.KPIContainer>
      </S.Card>
    </S.CardContainer>

    <S.TotalContainer>
      <p>PONTUAÇÃO<br />MÁXIMA</p>
      <div>
        TOTAL 60 ESCUDOS
        <S.Escudo />
      </div>
    </S.TotalContainer>
    <S.Exemplo>
      <p><strong>FONTE DE APURAÇÃO: SELL-IN MDLZ</strong></p>
      <p>O target do Gerente de área será a soma do target do seu time, assim como o resultado.</p>
    </S.Exemplo>
    <S.TituloQuem src={titulo2} alt="" />
    <S.Name>
      FOOD + DIA + FARMA
    </S.Name>

    <S.HeroiTable>
      <S.TableContainer>
        <S.Table>
          <tr>
            <th>CARGO</th>
            <td rowSpan={4}>
              <S.Vertical />
            </td>
            <th>QNTD GANHADORES</th>
            <td rowSpan={4}>
              <S.Vertical />
            </td>
            <th>PONTOS<br />ONDA 4</th>
          </tr>
          <tr>
            <td>EXECUTIVOS +<br />COORDENADORES</td>
            <td>2</td>
            <td rowSpan={2}>1000</td>
          </tr>
          <tr>
            <td>GERENTES DE ÁREA</td>
            <td>1</td>
          </tr>
        </S.Table>
      </S.TableContainer>
      <S.HeroContainer>
        <img src={heroi} alt="" />
      </S.HeroContainer>
    </S.HeroiTable>
  </S.Container>
}

export default MecanicaFDF