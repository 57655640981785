import * as S from './style'

import arrow from '../../../../assets/img/regulamento/arrow.png'

export default function FlowCard ({ hasArrow, description, title }) {
  return <S.FlowCardContainer>
    <h2>{title}</h2>

    <p>{description}</p>

    {hasArrow && <img src={arrow} alt="" />}
  </S.FlowCardContainer>
}
