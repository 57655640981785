import PageLoader from '../../components/PageLoader';
import { useAuth } from '../../contexts/AuthContext';
import MecanicaATC from '../Mecanica/MecanicaATC';
import MecanicaCC from '../Mecanica/MecanicaCC';
import MecanicaFDF from '../Mecanica/MecanicaFDF';
import MecanicaGKA from '../Mecanica/MecanicaGKA';
import MecanicaHIB from '../Mecanica/MecanicaHIB';
import MecanicaLMT from '../Mecanica/MecanicaLMT';
import MecanicaMerchan from '../Mecanica/MecanicaMerchan';
import MecanicaTD from '../Mecanica/MecanicaTD';
import MecanicaVJ from '../Mecanica/MecanicaVJ';
import SectionAwarded from './SectionAwarded';
import SectionCampaignFlow from './SectionCampaignFlow';
import SectionConcepts from './SectionConcepts';
import SectionConditions from './SectionConditions';
import SectionPrize from './SectionPrize';
import SectionRanking from './SectionRanking';
import SectionRules from './SectionRules';
import SectionTerms from './SectionTerms';
import SectionWaves from './SectionWaves';
import Template from "../../template";

import * as S from './style'

export const RegulamentoContent = ({ channelName }) => {
  return <S.RegulamentoContainer className="widget-box">
    <SectionWaves />

    <SectionRanking />

    <SectionRules />

    <S.RegulamentoTitle>
      <h1>
        REGULAMENTO
        <br />
        GIGANTES DA EXECUÇÃO 2023
      </h1>
    </S.RegulamentoTitle>

    <SectionConditions />

    <SectionConcepts />

    <SectionCampaignFlow />

    <SectionPrize />

    <SectionTerms />

    <SectionAwarded channelName={channelName} />
  </S.RegulamentoContainer>
}

function Regulamento() {
  const { user } = useAuth();

  if (!user) return <PageLoader />

  return <Template>
    <RegulamentoContent channelName={user.channel.name} />
    {user.channel.name === 'MERCHAN' && <MecanicaMerchan />}
    {user.channel.name !== 'MERCHAN' && <>
      <MecanicaCC />
      <MecanicaGKA />
      <MecanicaLMT />
      <MecanicaFDF />
      <MecanicaVJ />
      <MecanicaTD />
      <MecanicaHIB />
      <MecanicaATC />
    </>}
  </Template>
}

export default Regulamento;
