import heroi from '../../../assets/img/mecanicas/step-2/heroi-roxo.png'
import logoBisExtra from '../../../assets/img/mecanicas/step-2/logo-bis-extra.png'
import logoHeroi from '../../../assets/img/mecanicas/step-2/logo-heroi-roxo.png'
import logoMondelez from '../../../assets/img/mecanicas/step-2/logo-mondelez.png'
import logoOuro from '../../../assets/img/mecanicas/step-2/logo-ouro-branco.png'
import logoSonho from '../../../assets/img/mecanicas/step-2/logo-sonho-de-valsa.png'
import logoTridentMax from '../../../assets/img/mecanicas/step-2/logo-trident-max.png'
import logoTrident from '../../../assets/img/mecanicas/step-2/logo-trident.png'
import titulo2 from '../../../assets/img/mecanicas/step-2/titulo-roxo.png'
import titulo from '../../../assets/img/mecanicas/step-2/titulo-vj.png'
import * as S from './styled'

export default function MecanicaVJ() {
  return <S.Container>
    <S.TitleContainer>
      <S.TitleLogo>
        <img src={logoHeroi} alt="" />
      </S.TitleLogo>
      <S.Title>
        <img src={titulo} alt="" />
      </S.Title>
    </S.TitleContainer>
    <S.KPITitle>KPI´s</S.KPITitle>
    <S.CardContainer>
      <S.Card>
        <S.CardTitle>
          <h2>SKU por PDV</h2>
          <p>Target média Out a Dez - RTM</p>
        </S.CardTitle>
        <hr />
        <S.KPIContainer>
          <S.KPIText>
            <h3>Total marcas</h3>
            <S.Logos>
              <div>
                <img src={logoMondelez} alt="" style={{ maxWidth: "84px" }} />
              </div>
            </S.Logos>
          </S.KPIText>
          <S.InfoText>

            100 a 100,9% = 1 escudos
            <br />
            101 a 105,9% = 3 escudos
            <br />
            ≥106% = 5 escudos

            <p>Em relação ao atingimento do realizado</p>
          </S.InfoText>
        </S.KPIContainer>
        <hr />
        <S.EscudosContainer>
          <div>
            <h3>5 escudos</h3>
            <S.MiniEscudo />
          </div>
        </S.EscudosContainer>
      </S.Card>


      <S.Card>
        <S.CardTitle>
          <h2>Positivação Total</h2>
          <p>Target média Out a Dez - RTM</p>
        </S.CardTitle>
        <hr />
        <S.KPIContainer>
          <S.KPIText>
            <h3>Total marcas</h3>
            <S.Logos>
              <div>
                <img src={logoMondelez} alt="" style={{ maxWidth: "84px" }} />
              </div>
            </S.Logos>
          </S.KPIText>
          <S.InfoText>
            100 a 100,9% = 1 escudos
            <br />
            101 a 105,9% = 3 escudos
            <br />
            ≥106% = 5 escudos
            <br />
            <p>Em relação ao atingimento do<br />realizado</p>
          </S.InfoText>
        </S.KPIContainer>
        <hr />
        <S.KPIContainer>
          <S.KPIText>
            <h3>Marca ouro</h3>
            <S.Logos>
              <div>
                <img src={logoBisExtra} alt="" style={{ maxWidth: "36px" }} />
              </div>
            </S.Logos>
          </S.KPIText>
          <S.InfoText>
            100 a 100,9% = 2 escudos
            <br />
            101 a 105,9% = 6 escudos
            <br />
            ≥106% = 15 escudos
            <br />
            <p>Em relação ao atingimento do realizado</p>
          </S.InfoText>
        </S.KPIContainer>
        <hr />
        <S.EscudosContainer>
          <div>
            <h3>20 escudos</h3>
            <S.MiniEscudo />
          </div>
        </S.EscudosContainer>
      </S.Card>

      <S.Card>
        <S.CardTitle>
          <h2>Positivação Sonho de Valsa + Ouro Branco + Trident e Trident Max</h2>
          <p>Média do % atingimento dos sabores foco Target: Out a Dez - RTM e Trident Max - Trade</p>
        </S.CardTitle>
        <hr />
        <S.KPIContainer>
          <S.KPIText>
            <h3>Marca ouro</h3>
            <S.Logos>
              <div>
                <img src={logoSonho} alt="" style={{ maxWidth: "36px" }} />
              </div>
              <div>
                <img src={logoOuro} alt="" style={{ maxWidth: "36px" }} />
              </div>
            </S.Logos>
          </S.KPIText>
          <S.InfoText>
            100 a 100,9% = 1 escudos
            <br />
            101 a 105,9% = 3 escudos
            <br />
            ≥106% = 5 escudos
            <br />
            <p>Em relação ao atingimento do realizado</p>
          </S.InfoText>
        </S.KPIContainer>
        <hr />
        <S.KPIContainer>
          <S.KPIText>
            <h3>Marca foco</h3>
            <S.Logos>
              <div>
                <img src={logoTrident} alt="" style={{ marginRight: '4px', maxWidth: "36px" }} />
              </div>
              <div>
                <img src={logoTridentMax} alt="" style={{ marginLeft: '4px', maxWidth: "36px" }} />
              </div>
            </S.Logos>
          </S.KPIText>
          <S.InfoText>
            100 a 100,9% = 5 escudos
            <br />
            101 a 105,9% = 10 escudos
            <br />
            ≥106% = 20 escudos
            <br />
            <p>Em relação ao atingimento do realizado</p>
          </S.InfoText>
        </S.KPIContainer>
        <hr />
        <S.EscudosContainer>
          <div>
            <h3>25 escudos</h3>
            <S.MiniEscudo />
          </div>
        </S.EscudosContainer>
      </S.Card>
    </S.CardContainer>

    <S.TotalContainer>
      <p>PONTUAÇÃO<br />MÁXIMA</p>
      <div>
        TOTAL 50 ESCUDOS
        <S.Escudo />
      </div>
    </S.TotalContainer>
    <S.Exemplo>
      <p><strong>FONTE DE APURAÇÃO: VISOR</strong></p>
      <p><strong>NÃO TEREMOS GERENTE DE ÁREA CONCORRENDO NO VJ</strong></p>
      <p>*Para os vendedores que atendem mais de um cliente, os targets dos KPIs serão somados, assim como seus resultados. Vendedores/Gerentes que atendem mais de um canal, serão avaliados pelo canal mais representativo em sua hierarquia.</p>
    </S.Exemplo>
    <S.TituloQuem src={titulo2} alt="" />
    <S.Name>
      VJ
    </S.Name>

    <S.HeroiTable>
      <S.TableContainer>
        <S.Table>
          <tr>
            <th>CARGO</th>
            <td rowSpan={4}>
              <S.Vertical />
            </td>
            <th>QNTD GANHADORES</th>
            <td rowSpan={4}>
              <S.Vertical />
            </td>
            <th>PONTOS<br />ONDA 4</th>
          </tr>
          <tr>
            <td>EXECUTIVOS + COORDENADORES</td>
            <td>3</td>
            <td>1.000</td>
          </tr>
        </S.Table>
      </S.TableContainer>
      <S.HeroContainer>
        <img src={heroi} alt="" />
      </S.HeroContainer>
    </S.HeroiTable>
  </S.Container>
}
