import styled from "styled-components"

export const Container = styled.div`
  &>div {
    display: flex;
    align-items: center;
  }
`

export const Checkbox = styled.input`
  margin-right: .5rem;
  aspect-ratio: 1;
  flex: 1 0 21px;
  @media (max-width: 480px) {
    flex: 1 0 24px;
  }
`

export const Label = styled.label`
  user-select: none;

  a {
    color: #00c7d9;
    text-decoration: underline;
  }
`
