import { useCallback } from "react"
import InputText from '../../../components/Forms/InputText'
import QuestionTitle from '../QuestionTitle'

function TextQuestion(question) {
  const handleChange = useCallback((evt) => {
    question.onChange({
      questionId: question.id,
      answerId: parseInt(evt.target.value)
    })
  }, [question])
  return <li>
    <QuestionTitle>{question.title}</QuestionTitle>
    {question.description ?? <p>{question.description}</p>}
    <InputText onChange={handleChange} />
  </li>
}

export default TextQuestion