import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import HeroiRoxo from "../../assets/img/heroi-roxo.png";
import HeroinaVerde from "../../assets/img/heroina-verde.png";
import logo from '../../assets/img/logos-gigantes-2023.png';
import Cargo from "../../components/CargoComponent";
import InputPassword from "../../components/Forms/InputPassword";
import InputText from "../../components/Forms/InputText";
import RegionSelectorComponent from '../../components/RegionSelectorComponent';
import Row from '../../components/Row';
import GoogleSocialButton from '../../components/SocialButton/Google';
import schema from '../../config/selfRegisterFormScheme.json';
import { useAuth } from '../../contexts/AuthContext';
import { useModal } from '../../contexts/ModalContext';
import { FormValidator } from "../../domain/FormValidator";
import { api } from '../../services/api';
import { MaskDocument, MaskPhone } from "../../utils/mask";
import RecoveryPassForm from './RecoveryPassForm';
import * as S from './style';
import { ContainerHolder, ContainerLogin, ContainerRegister } from "./style";


const formValidator = new FormValidator(schema);

function SuccessModal() {
    return <>
        <p style={{ fontSize: '1.75rem' }}>Bem-vindo a Campanha de incentivo <strong>"Vitamine suas vendas com TANG"</strong></p>
        <p style={{ fontSize: '1.75rem' }}>Para acessar a plataforma clique no botão LOGIN e siga os próximo passos.</p>
        <p style={{ fontSize: '1.75rem', marginTop: '0.75rem' }}><strong>Login</strong>: Email (ponto focal)</p>
        <p style={{ fontSize: '1.75rem' }}><strong>Senha</strong>: Email (ponto focal)</p>
        <p style={{ fontSize: '1.75rem' }}><h2><center style={{ marginTop: '1.5rem', marginBottom: '0.75rem' }}><strong>Cadastro de Lojas</strong></center></h2></p>
        <p style={{ fontSize: '1.75rem' }}>Cadastre todas as lojas que pertencem a sua rede para participar da campanha</p>
    </>
}

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const { openModal } = useModal();
    const [loginOrRegister, setLoginOrRegister] = useState('login');

    const { doLogin } = useAuth();

    const [user, setUser] = useState({});

    async function handleLogin(event) {
        event.preventDefault();
        await doLogin(email, password)
    }

    async function handleRegister(event) {
        event.preventDefault();
        try {
            let hasError = false
            if (!formValidator.validate(user)) {
                setErrors(formValidator.errors)
                hasError = true
            }
            if (hasError) return
            await api.post('register', {
                ...user,
                companyId: String(process.env.REACT_APP_COMPANY_ID)
            })

            setLoginOrRegister('login');

            openModal({
                header: <h1>Parabéns!</h1>,
                body: <SuccessModal />,
                size: 'large'
            })

        } catch (err) {
            if (err.response.data.message) {
                toast.error(err.response.data.message, {
                    theme: "colored"
                });
            } else {
                switch (err.response.data) {
                    case "Value already exists: user":
                        toast.error("Você já está cadastrado", {
                            theme: "colored"
                        });
                        break;
                    default:
                        toast.error("Ocorreu um erro um erro interno no servidor", {
                            theme: "colored"
                        });
                        break;

                }
            }
        }
    }

    const handleMask = useCallback((login) => {
        const charCode = login.charCodeAt()
        if (charCode > 47 && charCode < 58) {
            return MaskDocument(login)
        }
        return login
    }, [])

    const handleAskRecovery = useCallback(() => {
        openModal({
            header: "Redefinir Senha",
            body: <RecoveryPassForm />,
        })
    }, [openModal])

    return (
        <S.Landing className="landing">
            <div className="landing-decoration"></div>

            <div className="landing-info">
                <div className="logo"></div>

                {/* <div className="landing-info-pretitle"></div>

                <h1 className="landing-info-title">BEM-VINDO</h1>

                <p className="landing-info-text">à campanha Vitamine suas Vendas com Tang. <br />Um novo produto mais saudável, nutritivo e um grande aliado na alimentação das famílias brasileiras!<br /> Vamos juntos transformar a categoria?</p> */}

            </div>

            <div className="landing-form">
                {loginOrRegister === 'login' ?
                    <ContainerLogin className="form-box login-register-form-element login">
                        <img src={logo} className="logo-home" alt="Logo tang" />
                        <img className="form-box-decoration left" src={HeroiRoxo} alt="heroi" />
                        <img className="form-box-decoration right" src={HeroinaVerde} alt="heroina" />
                        <form className="form" onSubmit={handleLogin}>
                            <div className="form-row">
                                <div className="form-item">
                                    <InputText name="login-username" label="Email ou matrícula" onChange={(email) => {
                                        console.log(email)
                                        setEmail(email)
                                    }} mask={handleMask} />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-item">
                                    <InputPassword name="login-password" className="form__input__focus" label="Senha" onChange={(password) => {
                                        setPassword(password)
                                    }
                                    } />
                                </div>
                            </div>

                            <div className="form-row space-between">
                                <div className="form-item">
                                    <p className="form-link cursor-pointer" type="text" onClick={() => handleAskRecovery()}>Recuperar a senha?</p>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-item">
                                    <button className="button medium secondary" type="submit">
                                        Acesse
                                    </button>

                                    <S.Line>
                                        <div>Ou</div>
                                    </S.Line>

                                    <GoogleSocialButton />
                                </div>
                            </div>
                        </form>
                        <p className="form-text form-link cursor-pointer">Se você tiver problemas, entre em contato pelo e-mail <a href="mailto: contato@gigantedaexecucao.com">contato@gigantedaexecucao.com</a></p>
                    </ContainerLogin>
                    :
                    <ContainerRegister className="form-box login-register-form-element register">
                        <img className="form-box-decoration left" src={HeroiRoxo} alt="heroi" />
                        <img className="form-box-decoration right" src={HeroinaVerde} alt="heroina" />

                        <h2 className="form-box-title">Crie sua conta!</h2>

                        <ContainerHolder>
                            <form className="form" onSubmit={handleRegister}>
                                <Row>
                                    <InputText name="email" label="Email" value={user.email} onChange={(email) => setUser({ ...user, email })} error={errors.email} />
                                </Row>
                                <Row>
                                    <InputText name="name" label="Name" value={user.name} onChange={(name) => setUser({ ...user, name })} error={errors.name} />
                                </Row>
                                <Row>
                                    <Cargo name="cargo" label="Cargo" value={user.cargo} onChange={(cargo) => setUser({ ...user, cargo })} error={errors.cargo} />
                                </Row>
                                <Row>
                                    <InputText name="phone" label="Celular comercial" value={user.phone} onChange={(phone) => setUser({ ...user, phone })} mask={MaskPhone} error={errors.phone} />
                                </Row>
                                <Row>
                                    <InputText name="document" label="CPF" value={user.document} onChange={(document) => setUser({ ...user, document })} mask={MaskDocument} error={errors.document} />
                                </Row>
                                <Row>
                                    <InputText name="cnpj" label="CNPJ da rede" value={user.cnpj} onChange={(cnpj) => setUser({ ...user, cnpj })} mask={MaskDocument} error={errors.cnpj} />
                                </Row>
                                <Row>
                                    <InputText name="holding-name" label="Nome da rede" value={user.holding} onChange={(holding) => setUser({ ...user, holding })} error={errors.holding} />
                                </Row>
                                <Row>
                                    <RegionSelectorComponent name="region-id" label="Selecione a Regional" value={user.region} onChange={(region) => setUser({ ...user, regionId: region.id })} error={errors.regionId} />
                                </Row>

                                <div className="form-row">
                                    <div className="form-item">
                                        <button className="button medium primary" type="submit">Registrar</button>
                                    </div>
                                </div>
                            </form>

                            <p className="form-text">Você receberá a confirmação de cadastro por e-mail.<br />Se você tiver problemas, entre em contato pelo e-mail <a href="mailto: contato@gigantedaexecucao.com">contato@gigantedaexecucao.com</a></p>
                        </ContainerHolder>
                    </ContainerRegister>
                }
            </div>
        </S.Landing>
    )
}