import * as S from './style'

import durationIcon from '../../../../assets/img/regulamento/concepts-duration.png'
import mecanicsIcon from '../../../../assets/img/regulamento/concepts-mecanics.png'
import productsIcon from '../../../../assets/img/regulamento/concepts-products.png'

const icons = {
  duração: durationIcon,
  mecânica: mecanicsIcon,
  produtos: productsIcon,
}

export  default function ConceptCard ({ description, title }) {
  return <S.ConceptCardContainer>
    <img src={icons[title]} alt="" />

    <S.ConceptContent>
      <h1>{title}</h1>

      <p>{description}</p>
    </S.ConceptContent>
  </S.ConceptCardContainer>
}
