import styled from "styled-components";

export const ConditionsSectionContainer = styled.section`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 3.125rem 0 4.375rem;

  > div:has(img) {
    display: flex;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 1550px) {
    grid-template-columns: 1fr;
    justify-content: center;

    > div:has(img) {
      img {
        margin-bottom: 1.5rem;
        width: 50%;
      }
    }
  }
`

export const ConditionsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h1 {
    color: #92FCFF;
    font-family: MDLZBiteType, sans-serif;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: clamp(3rem, 2.7383rem + 1.163vw, 4.1368rem);
    letter-spacing: -0.2413rem;
    margin-bottom: 16px;
    text-transform: uppercase;
  }
`

export const ConditionsItem = styled.div`
  h2, p, a {
    font-family: Montserrat, sans-serif;
    font-feature-settings: 'clig' off, 'liga' off;
  }

  h2 {
    color: var(--white);
    font-size: clamp(1.5rem, 1.3274rem + 0.7673vw, 2.25rem);
    font-weight: 900;
    line-height: 2.8958rem;
  }

  p {
    color: var(--white);

    &, a {
      font-size: clamp(1rem, 0.8849rem + 0.5115vw, 1.5rem);
      line-height: clamp(2rem, 1.8274rem + 0.7673vw, 2.75rem);
    }
  }

  a {
    color: #01FFFF;
    font-weight: 700;
    text-decoration-line: underline;
  }
`

export const ConditionsAttention = styled.p`
  color: var(--white);

  &, span {
    font-family: Montserrat, sans-serif;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: clamp(1.125rem, 1.0056rem + 0.5307vw, 1.6438rem);
    font-style: normal;
    font-weight: 900;
    line-height: clamp(1.5rem, 1.3274rem + 0.7673vw, 2.25rem);
  }

  span {
    color: #FF03F5;
  }
`
