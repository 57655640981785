import { useCallback } from "react"
import QuestionTitle from '../QuestionTitle'
import * as S from './styled'

function RadioQuestion(question) {
  const handleChange = useCallback((evt) => {
    question.onChange({
      questionId: question.id,
      alternativeId: parseInt(evt.target.value)
    })
  }, [question])
  return <S.Container>
    <QuestionTitle>{question.title}</QuestionTitle>
    {question.description ?? <p>{question.description}</p>}
    {question.alternatives.map(a => <S.Label>
      <S.Input type="radio" name={`radio_${question.id}`} id={`radio_${question.id}`} value={a.id} onChange={handleChange} />
      {a.title}
    </S.Label>)}
  </S.Container>
}

export default RadioQuestion