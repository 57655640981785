import * as S from './style'

import logo from '../../../assets/img/logos-gigantes-2023.png'

export default function SectionConditions () {
  return <S.ConditionsSectionContainer>
    <div>
      <img src={logo} alt="" />
    </div>

    <S.ConditionsContentContainer>
      <h1>CONDIÇÕES GERAIS</h1>

      <S.ConditionsItem>
        <h2>Objetivos</h2>

        <p>Premiar os participantes que atingirem as metas (Anexo I) da Campanha.</p>
      </S.ConditionsItem>

      <S.ConditionsItem>
        <h2>Participantes</h2>

        <p>Empregados da Mondelēz do time de Vendas com contrato ativo.</p>
      </S.ConditionsItem>

      <S.ConditionsItem>
        <h2>Requisitos</h2>

        <p>
          Se cadastrar no site
          {' '}
          <a href="https://gigantedaexecucao.com/" rel="noreferrer" target="_blank">https://gigantedaexecucao.com/</a>
          {' '}
          aceitar os termos da Campanha.
        </p>
      </S.ConditionsItem>

      <S.ConditionsAttention>
        <span>Alterações:</span>
        {' '}
        Toda e qualquer alteração que ocorra no curso da campanha será devidamente informada aos empregados participantes.
      </S.ConditionsAttention>
    </S.ConditionsContentContainer>
  </S.ConditionsSectionContainer>
}
