import ProgressBar from '../ProgressBar'
import InputSelect from '../../../components/Forms/InputSelect'
import * as S from './styled'

function TableCell({ value }) {
  if (typeof value === 'object') {
    return <td><ProgressBar value={value.value} /></td>
  }
  return <td>{value}</td>
}

function DashboardTable({ title, header, content, filter, onChange }) {
  return <S.Container>
    <S.Title>
      <p>{title}</p>
      <p>
        {filter && <InputSelect onChange={onChange}>
          <option value="">Selecione uma holding</option>
          {filter.values.map(f => <option value={f.id}>{f.name}</option>)}
        </InputSelect>}
      </p>
    </S.Title>
    <S.Table>
      <thead>
        <tr>
          {header.map(h => <th>{h}</th>)}
        </tr>
      </thead>
      <tbody>
        {content.map(con => <tr>{
          con.map(c => <TableCell value={c} />)
        }</tr>)}
      </tbody>
    </S.Table>
  </S.Container>
}

export default DashboardTable