import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;

  &.mobile {
    display: none;
  }
  
  @media (max-width: 1024px) {
    max-width: 100%;
    display: none;

    &.mobile {
      display: block;
    }
  }
`;

export const ImgReg = styled.img`
  display: block;
  height: 100%;
  max-width: 100%;
`

export const BtGroup = styled.button`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;
`

export const Danger = styled.button.attrs(() => {
  return {
    className: 'button secondary'
  }
})`
`

export const Success = styled.button.attrs(() => {
  return {
    className: 'button secondary'
  }
})`
`
