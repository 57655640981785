import styled from "styled-components"
import { Link } from 'react-router-dom'

export const Container = styled(Link).attrs(() => {
  return {
    className: 'button primary'
  }
})`
  border-radius: 1rem;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  flex: 1 30%;
  justify-content: center;
  align-items: center;
`