import styled from "styled-components"

export const Container = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  width: 100%;

  & > li {
    align-items: center;
    border: 1px solid #000;
    display: flex;
    flex: 1 100%;
    justify-content: center;
    width: auto;

    button {
      background-color: transparent;
      border: 0 none;
      border-radius: 0;
      color: inherit;
      inset: 0;
      outline: 0 none;
      padding: 16px 0;
    }

    &.active {
      background-color: #000;
      color: #fff;
      font-weight: bold;
    }
  }
`