import { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import api from "../../services/api";
import Template from "../../template";
import QuizRankingComponent from '../../components/QuizRanking';
import BannerTitleComponent from "../../components/BannerTitle";
import AccountHubIcon from '../../assets/img/banner/icon-quiz.png';
import * as S from './style'

function QuizInterno() {
  const { quizId } = useParams();
  const { token } = useAuth();
  const [quiz, setQuiz] = useState(0);
  const loadData = useCallback(async () => {
    const response = await api.get(`/quiz/${quizId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    setQuiz(response.data);
  }, [quizId, token])

  useEffect(() => {
    loadData();

  }, [loadData])

  return <Template>
    <BannerTitleComponent
      imgUrl={AccountHubIcon}
      title="Quiz"
      subTitle="total"
      imgAlt="accounthub-icon"
    />
    <div className="grid medium-space">
      <div className="account-hub-content">

        <div className="grid-column">
          <div className="widget-box">

            <div className="widget-box-content">
              <S.Pergunta>
                <h4>Você fez um total de {quiz.points} pontos</h4>

                <QuizRankingComponent id={quizId} />

                <S.Link to="/quiz">Voltar</S.Link>
              </S.Pergunta>
            </div>
          </div>

        </div>
      </div>
    </div>

  </Template>;
}

export default QuizInterno;