import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import PageLoader from '../../components/PageLoader'
import api from '../../services/api'
import Template from "../../template"
import Card from './Card'
import RadioQuestion from './RadioQuestion'
import RangeQuestion from './RangeQuestion'
import TextAreaQuestion from './TextAreaQuestion'
import TextQuestion from './TextQuestion'
import * as S from './styled'

const loadSurvey = async (id) => {
  return (await api.get(`/survey/${id}`)).data
}

const saveSurvey = async (id, questions) => {
  return (await api.post(`/survey/${id}`, { questions })).data
}

const questionInteraction = [{
  questionId: 6,
  contidion: {
    questionId: 5,
    alternatives: [8, 9, 10]
  }
}, {
  questionId: 7,
  contidion: {
    questionId: 5,
    alternatives: [6, 7, 8, 9, 10]
  }
},
{
  questionId: 8,
  contidion: {
    questionId: 5,
    alternatives: [6, 7, 8, 9, 10]
  }
},
{
  questionId: 9,
  contidion: {
    questionId: 5,
    alternatives: [6, 7, 8, 9, 10]
  }
},
{
  questionId: 10,
  contidion: {
    questionId: 5,
    alternatives: [6, 7, 8, 9, 10]
  }
},
{
  questionId: 11,
  contidion: {
    questionId: 10,
    alternatives: [18, 19, 20]
  }
},
{
  questionId: 12,
  contidion: {
    questionId: 10,
    alternatives: [16, 17, 18, 19, 20]
  }
},
{
  questionId: 13,
  contidion: {
    questionId: 10,
    alternatives: [16, 17, 18, 19, 20]
  }
},
{
  questionId: 14,
  contidion: {
    questionId: 10,
    alternatives: [16, 17, 18, 19, 20]
  }
},
{
  questionId: 15,
  contidion: {
    questionId: 10,
    alternatives: [16, 17, 18, 19, 20]
  }
}]

function Survey() {
  const [answer, setAnswer] = useState([]);
  const [survey, setSurvey] = useState()

  const filter = useMemo(() => {
    if (!survey) return []
    return survey.questions.filter((q) => {
      const condition = questionInteraction.find(i => i.questionId === q.id)
      if (!condition) return true
      const questionAnswer = answer.find(a => a.questionId === condition.contidion.questionId)
      if (!questionAnswer) return false
      if (condition.contidion.alternatives.includes(questionAnswer.alternativeId)) {
        return true
      }
      console.log(questionAnswer)
      return false
    })
  }, [answer, survey])

  const dateFormat = useCallback((dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('pt-br').replace(/\/[0-9]{4}/, '')
  }, [])

  const handleUpdateAnswer = useCallback(({ questionId, alternativeId, response }) => {
    const answerIndex = answer.findIndex(a => a.questionId === questionId)
    if (answerIndex <= -1) {
      answer.push({ questionId, alternativeId, response })
    } else {
      answer[answerIndex].alternativeId = alternativeId
      answer[answerIndex].response = response
    }
    setAnswer([...answer])
  }, [answer])

  const handleSave = useCallback(async (evt) => {
    evt.preventDefault()
    try {
      await saveSurvey(1, answer)
      toast.success('Obrigado por respoder a pesquisa!', { theme: 'colored' })
    } catch (error) {
      switch (error.response.status) {
        case 400:
          toast.error(error.response.data, { theme: 'colored' })
          break;
        case 500:
        default:
          toast.error('Ocorreu um erro inesperado. Por favor tente novamente mais tarde!', { theme: 'colored' })
          break;
      }
    }
  }, [answer])

  useEffect(() => {
    (async () => {
      const survey = await loadSurvey(1)
      setSurvey(survey.content)
    })()
  }, [])

  if (!survey) return <PageLoader />

  return <Template>
    <Card>
      <form onSubmit={handleSave}>
        <h1>{survey.title}</h1>
        <p>Responda essa pesquisa de {dateFormat(survey.enableAt)} a {dateFormat(survey.disableAt)}!</p>
        <S.Container>
          {filter.map(q => {
            switch (q.questionFormat) {
              case 'ALTERNATIVA':
                return <RadioQuestion {...q} onChange={handleUpdateAnswer} />
              case 'ALTERNATIVA_NUMERICA':
                return <RangeQuestion {...q} onChange={handleUpdateAnswer} />
              case 'DISSERTATIVA_AREA':
                return <TextAreaQuestion {...q} onChange={handleUpdateAnswer} />
              case 'DISSERTATIVA':
              default:
                return <TextQuestion {...q} onChange={handleUpdateAnswer} />
            }
          })}
        </S.Container>

        <button type="submit" className="primary button">Enviar</button>
      </form>
    </Card>
  </Template>
}

export default Survey