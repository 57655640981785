import { useCallback, useEffect, useState } from 'react'
import InputSelect from '../Forms/InputSelect'
import api from '../../services/api'

function ChannelComponent({ ...rest }) {
  const [channels, setChannels] = useState()

  const loadChannels = useCallback(async () => {
    const response = await api.get(`/channels`)
    setChannels(response.data)
  }, [])

  useEffect(() => {
    loadChannels();
  }, [loadChannels])

  return <InputSelect {...rest} name="canal" label="Canal">
    <option value="">Selecione o canal</option>
    {channels && channels.map(c => <option selected={rest.value === c.id} value={c.id}>{c.name}</option>)}
  </InputSelect>
}

export default ChannelComponent
