import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import imageRegBoost1 from '../../assets/img/mecanicas/boost/regulamento/slide-1.png'
import imageRegBoost2 from '../../assets/img/mecanicas/boost/regulamento/slide-2.png'
import imageRegBoost3 from '../../assets/img/mecanicas/boost/regulamento/slide-3.png'
import imageRegBoost4 from '../../assets/img/mecanicas/boost/regulamento/slide-4.png'
import imageDeskBoost1 from '../../assets/img/mecanicas/boost/slide-1.jpg'
import imageDeskBoost2 from '../../assets/img/mecanicas/boost/slide-2.jpg'
import imageMobileBoost1 from '../../assets/img/mecanicas/boost/slide-merchan-1.png'
import imageMobileBoost2 from '../../assets/img/mecanicas/boost/slide-mobile-2.jpg'
import imageMobileVendasBoost1 from '../../assets/img/mecanicas/boost/slide-vendas-1.png'
import imageDefault from '../../assets/img/pascoa-default.jpg'
import imageMerchan from '../../assets/img/pascoa-merchan.jpg'
import { useAuth } from "../../contexts/AuthContext"
import { useLockedModal } from '../../contexts/LockedModalContext'
import api from '../../services/api'
import Template from '../../template'
import * as S from './style'

function MecanicasExtras() {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { name } = useParams()
  const { openModal, closeModal } = useLockedModal()
  const handleReject = useCallback(() => {
    closeModal()
    navigate('/home')
  }, [closeModal, navigate])
  const handleAccept = useCallback(async () => {
    closeModal()
    await api.post('/accept-boost')
  }, [closeModal])
  const image = useMemo(() => {
    switch (name) {
      case 'pascoa':
        const img = user.channel.team.name === 'VENDAS' ? imageMerchan : imageDefault
        return { desk: [img], mobile: [img] }
      case 'boost':
        return { desk: [imageDeskBoost1, imageDeskBoost2], mobile: [imageMobileBoost1, imageMobileVendasBoost1, imageMobileBoost2] }
      default:
        return []
    }
  }, [user, name])
  useEffect(() => {
    if (!user.acceptBoost) {
      openModal({
        header: 'Mecânica de boost',
        size: 'large',
        body: <>
          <S.ImgReg src={imageRegBoost1} alt="" />
          <S.ImgReg src={imageRegBoost2} alt="" />
          <S.ImgReg src={imageRegBoost3} alt="" />
          <S.ImgReg src={imageRegBoost4} alt="" />
          <S.BtGroup>
            <S.Danger onClick={handleReject}>Recusar</S.Danger>
            <S.Success onClick={handleAccept}>Aceitar</S.Success>
          </S.BtGroup>
        </>
      })
    }
  }, [handleAccept, handleReject, openModal, user.acceptBoost, user.accept_boost])
  return <Template>
    <S.Container>
      {image.desk.map(img => <S.Image className="desk" src={img} alt="Desk" />)}
      {image.mobile.map(img => <S.Image className="mobile" src={img} alt="Mobile" />)}
    </S.Container>
  </Template>
}

export default MecanicasExtras;
