import styled from "styled-components";

export const StepContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 2.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2rem 0;

  @media screen and (max-width: 1366px) {
    justify-content: center;
  }

  > div:first-child {
    h1 {
      color: var(--white);
      font-family: MontserratStatic, sans-serif;
      font-size: clamp(2rem, 1.4246rem + 2.5575vw, 4.5rem);
      font-weight: 900;
      text-align: left;
      text-transform: uppercase;
      white-space: nowrap;
      text-align: center;

      span {
        font-size: clamp(3rem, 2.0793rem + 4.0921vw, 7rem);
        -webkit-text-stroke: 2px var(--white);
        -webkit-text-fill-color: transparent;
      }
    }

    @media screen and (max-width: 1366px) {
      :not(:has(h1)) {
        display: none;
      }
    }
  }
`

export const StepContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 565px;
  width: 100%;

  > div {
    display: flex;
    gap: 1.75rem;


    > div:first-child {
      max-width: 92px;
      width: 100%;

      img {
        object-fit: contain;
      }

      @media screen and (max-width: 680px) {
        display: none;
      }
    }

    > div:last-child {
      max-width: 490px;

      p {
        font-family: Montserrat, sans-serif;
        text-transform: uppercase;

        :first-child {
          color: #92FCFF;
          font-size: clamp(2rem, 1.813rem + 0.8312vw, 2.8125rem);
          font-weight: 900;
          line-height: 2.625rem;
        }

        :not(:first-child) {
          color: var(--white);
          font-size: clamp(1rem, 0.9568rem + 0.1918vw, 1.1875rem);
          line-height: clamp(1rem, 0.7986rem + 0.8951vw, 1.875rem);
          margin-top: 1rem;
        }

        :nth-child(3) {
          text-transform: none;
        }
      }
    }
  }

  > div:has(> img) {
    img {
      width: 100%;
    }
  }
`