import { useEffect, useState } from 'react';
import api from '../../services/api';
import * as S from './styled';

function Premmiar({ open, wrapperRef }) {
  const [catalogUrl, setCatalogUrl] = useState('')
  const [openModal, setOpenModal] = useState(open)
  useEffect(() => {
    const loadCatalogUrl = async () => {
      const result = await api.get(`/premmiar`)
      setCatalogUrl(result.data.catalogUrl)
    }
    loadCatalogUrl()
  }, [])
  useEffect(() => {
    setOpenModal(open)
  }, [open])
  return <S.Container className={openModal ? 'open' : 'close'}>
    <iframe ref={wrapperRef} title="catalogo" src={catalogUrl} />
  </S.Container>
}

export default Premmiar