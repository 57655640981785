import { useEffect, useState, useCallback } from "react";
import { useFetch } from "../../hooks/useFetch";
import { toast } from "react-toastify";
import api from "../../services/api";
import Template from "../../template"
import AccountHubIcon from '../../assets/img/banner/accounthub-icon.png';
import PageLoaderComponent from "../../components/PageLoader";
import InputText from '../../components/Forms/InputText';
import RoleSelectorComponent from "../../components/RoleSelectorComponent";
import CargoComponent from '../../components/CargoComponent';
import ChannelComponent from '../../components/ChannelComponent';
import { MaskDocument, MaskPhone } from "../../utils/mask";
import * as S from './style';

// Component
import BannerTitleComponent from "../../components/BannerTitle"
import { useParams } from "react-router-dom";
import { useCampaign } from "../../contexts/CampaignContext";

export default function EditUser() {
  const [user, setUser] = useState({});
  const [, setRole] = useState({});
  const [, setHolding] = useState({});
  const [address, setAddress] = useState({});
  const [button, setButton] = useState({
    name: 'Carregando dados',
    isDisabled: true,
  });

  const { userId } = useParams();
  const { campaignData } = useCampaign()

  const { data } = useFetch(`/user/${userId}`);

  const handleUserSave = useCallback(async (e) => {
    e.preventDefault();
    setButton({
      name: 'Salvando dados',
      isDisabled: true,
    })
    try {
      await api.put(`/user/${user.id}`, { ...user, address });
      toast.success('Os dados foram salvos', { theme: "colored" });
    } catch (e) { }
    setButton({
      name: 'Salvar dados',
      isDisabled: false,
    })
  }, [user, address]);

  const handleApprove = useCallback(async (evt) => {
    evt.preventDefault()
    try {
      await api.patch(`/user/${user.id}/approve`)
      toast.success('Usuário aprovado com sucesso!', { theme: 'colored' })
    } catch (err) {
      toast.error('Ocorreu um erro e não foi possivel aprovar o usuário', { theme: 'colored' })
    }
  }, [user])

  useEffect(() => {
    if (!data) return
    setUser(data)
    setRole(data.role)
    setHolding(data.holding)
    setAddress(data.address || {})
    setButton({
      name: 'Salvar dados',
      isDisabled: false,
    })
  }, [campaignData, data])

  if (!user) return <PageLoaderComponent />;

  return (
    <Template>
      <BannerTitleComponent
        imgUrl={AccountHubIcon}
        title="Meu perfil"
        subTitle="Info de perfil, mensagens, configuração e muito mais!"
        imgAlt="accounthub-icon"
      />

      <div className="grid medium-space">
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">Meu perfil</p>

              <h2 className="section-title">Informação de perfil</h2>
            </div>
          </div>

          <div className="grid-column">

            <div className="widget-box">
              <p className="widget-box-title">Sobre seu perfil</p>

              <div className="widget-box-content">

                <form className="form" action="" method="POST" onSubmit={handleUserSave}>
                  <div className="form-row split">
                    <div className="form-item">
                      <RoleSelectorComponent value={user.role?.id} onChange={(role) => { setUser({ ...user, roleId: role.id }); setRole(role) }} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="name" label="Nome Completo" id="name" value={user.name} onChange={(value) => setUser({ ...user, name: value })} />
                    </div>

                    <div className="form-item">
                      <InputText name="email" label="Email Corporativo" id="email" value={user.email} onChange={(value) => setUser({ ...user, email: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="cellphone" label="Telefone Corporativo" id="cellphone" value={user.cellphone} onChange={(value) => setUser({ ...user, cellphone: MaskPhone(value) })} maxLength="15" />
                    </div>
                    <div className="form-item">
                      <InputText name="document" label="CPF" id="document" value={user.document} onChange={(value) => setUser({ ...user, document: MaskDocument(value) })} maxLength="14" />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <CargoComponent name="cargo" label="Cargo" id="cargo" value={user.cargo} onChange={(value) => setUser({ ...user, cargo: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="matricula" label="Matricula" id="matricula" value={user.matricula} onChange={(value) => setUser({ ...user, matricula: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <ChannelComponent name="channelId" label="Canal" id="channelId" value={user.channelId} onChange={(value) => setUser({ ...user, channelId: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    {!user.approved && <div className="form-item">
                      <button className="button primary add-field-button" onClick={handleApprove}>Aprovar usuário</button>
                    </div>}
                    <div className="form-item">
                      <S.SubmitButton className="button primary add-field-button" type="submit" disabled={button.isDisabled}>{button.name}</S.SubmitButton>
                    </div>

                  </div>

                </form>
              </div>
            </div>


          </div>
        </div>
      </div>
    </Template >
  )
}