import styled from "styled-components";

export const CampaignFlowSectionContainer = styled.section`
  padding: 0 clamp(1rem, -1.0716rem + 9.2072vw, 10rem) 4rem;

  > h1, p {
    font-feature-settings: 'clig' off, 'liga' off;
  }

  > h1 {
    color: #92FCFF;
    font-family: MDLZBiteType, sans-serif;
    font-size: clamp(3rem, 2.7026rem + 1.3217vw, 4.292rem);
    line-height: 3.8986rem;
    letter-spacing: -0.2504rem;
    margin-bottom: 1rem;
  }
`

export const FlowCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2.5rem;

  @media screen and (max-width: 1366px) {
    align-items: center;
    flex-direction: column;
  }
`

export const WarningContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 14px;
  margin-top: 2rem;

  p {
    color: var(--white);
    font-family: Montserrat, sans-serif;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: clamp(1.125rem, 1.0382rem + 0.3859vw, 1.5022rem);
    line-height: 1.9672rem;
  }

  @media screen and (max-width: 1142px) {
    flex-direction: column;
    text-align: justify;
  }
`
