import styled from "styled-components";

export const RulesSectionContainer = styled.section`
  align-items: center;
  background: linear-gradient(90deg, #330050 0%, #B93277 100%), url('buildings-bg.png') no-repeat bottom;
  background-size: cover;
  background-blend-mode: multiply;
  background-origin: padding-box;
  margin-inline: -1.75rem;
  padding: 2.75rem 0 2rem;
  `

export const RulesSectionContent = styled.div`
  display: flex;
  gap: 1.625rem;
  padding-inline: 1.75rem;
  position: relative;

  @media screen and (max-width: 820px) {
    justify-content: center;
  }

  > div {
    max-width: 792px;

    h1 {
      color: #92FCFF;
      font-family: MontserratStatic, sans-serif;
      font-size: clamp(1.5rem, 0.5793rem + 4.0921vw, 5.5rem);
      font-weight: 900;
      white-space: nowrap;

      span {
        :first-child {
          font-size: clamp(1.5rem, 0.8095rem + 3.0691vw, 4.5rem);
          -webkit-text-stroke: 2px var(--white);
          -webkit-text-fill-color: transparent;
        }

        :last-of-type {
          color: var(--white);
        }
      }
    }

    @media screen and (min-width: 945px) and (max-width: 1550px) {
      width: 50%;
    }
  }

  > div:has(img) {
    img {
      width: 100%;
    }

    @media screen and (max-width: 1550px) {
      position: absolute;
      right: clamp(1rem, -0.4962rem + 6.6496vw, 7.5rem);
      width: 36%;
    }

    @media screen and (max-width: 1366px) {
      right: 1rem;
    }

    @media screen and (max-width: 1280px) {
      display: none;
    }
  }
`
