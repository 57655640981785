import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import trofeu from '../../assets/img/quiz/bg-trofeu.png'
import { useAuth } from '../../contexts/AuthContext'
import { useModal } from '../../contexts/ModalContext'
import * as S from './style'

const templateList = {
    trofeu
}

export default function CardQuiz({ children, template, quiz, isNew }) {
    const bg = templateList[template];
    const navigate = useNavigate();
    const { openModal, closeModal } = useModal();
    const { userCan } = useAuth()
    const handleConfirm = useCallback((link) => {
        navigate(link)
        closeModal()
    }, [closeModal, navigate])
    const handleOpenQuiz = useCallback((link) => {
        openModal({
            title: 'Atenção',
            size: 'large',
            body: <S.ModalQuiz className="form-row">
                <p>Quando o Quiz for iniciado não poderá ser interrompido.</p>
                <p>São 5 perguntas no total.</p>
                <p>O tempo de duração de cada pergunta é de 60s.</p>
                <p>Apenas 1 alternativa é  a correta.</p>
                <p>Só é permitido jogar o Quiz uma vez. </p>
                <p>Disponível de {new Date(quiz.enableAt).toLocaleDateString('pt-br').replace(/\/\d{4}$/, '')} a {new Date(quiz.disableAt).toLocaleDateString('pt-br').replace(/\/\d{4}$/, '')}. </p>
                <p>Deseja iniciar agora?</p>

                <div style={{ display: 'flex' }}>
                    <button className="button medium tertiary" onClick={closeModal}>Não</button>
                    &nbsp;
                    <button className="button medium secondary" onClick={() => handleConfirm(link)}>Sim</button>
                </div>
            </S.ModalQuiz>
        });
    }, [openModal, quiz.enableAt, quiz.disableAt, closeModal, handleConfirm]);
    const enableAt = useMemo(() => new Date(quiz.enableAt), [quiz])
    const disableAt = useMemo(() => new Date(quiz.disableAt), [quiz])

    const disableButton = useMemo(() => {
        if (quiz.hasAnswer) return true
        if (userCan('quiz:answer-any-time') && isNew) return false
        if (enableAt > new Date()) return true
        if (disableAt < new Date()) return true
        return false
    }, [quiz.hasAnswer, userCan, isNew, enableAt, disableAt])

    const status = useMemo(() => {
        if (quiz.hasAnswer) return 'Respondido'
        if (userCan('quiz:answer-any-time') && isNew) return 'Disponivel'
        if (enableAt > new Date()) return 'Em breve'
        if (disableAt < new Date()) return 'Fechado'
        return 'Disponivel'
    }, [quiz.hasAnswer, userCan, isNew, enableAt, disableAt])

    const color = useMemo(() => {
        if (disableButton) return '#6c757d80'
        return 'var(--success)'
    }, [disableButton])

    const buttonText = useMemo(() => {
        if (quiz.hasAnswer) return 'Você já respondeu esse quiz'
        if (userCan('quiz:answer-any-time') && isNew) return 'Iniciar Quiz'
        if (enableAt > new Date()) return `Disponível em ${enableAt.toLocaleDateString('pt-BR').replace(/\/\d{4}$/, '')}`
        if (disableAt < new Date()) return 'Encerrado'
        return 'Iniciar Quiz';
    }, [quiz.hasAnswer, userCan, isNew, enableAt, disableAt]);
    return (
        <S.Principal bg={bg}>
            <div className="background"></div>
            <h3>{quiz.title}</h3>

            <S.Status color={color}>
                <p>{status}</p>
            </S.Status>

            <S.Score>
                {/* <div className="score">
                    <span>100</span>
                    <p>Realizados</p>
                </div> */}
                <div className="score">
                    <span>{quiz.points}</span>
                    <p>Pontos</p>
                    <S.Data>Disponível de {enableAt.toLocaleDateString('pt-BR').replace(/\/\d{4}$/, '')} a {disableAt.toLocaleDateString('pt-BR').replace(/\/\d{4}$/, '')}</S.Data>
                </div>
            </S.Score>
            <S.Link onClick={() => handleOpenQuiz(`/quiz/${quiz.id}`)} disabled={disableButton}>{buttonText}</S.Link>
        </S.Principal>
    )
}