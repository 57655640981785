import React, { useCallback } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import ImageUpload from "../../../components/Forms/ImageUpload";
import MecanicaATC from '../../Mecanica/MecanicaATC';
import MecanicaCC from '../../Mecanica/MecanicaCC';
import MecanicaFDF from '../../Mecanica/MecanicaFDF';
import MecanicaGKA from '../../Mecanica/MecanicaGKA';
import MecanicaHIB from '../../Mecanica/MecanicaHIB';
import MecanicaLMT from '../../Mecanica/MecanicaLMT';
import MecanicaMerchan from '../../Mecanica/MecanicaMerchan';
import MecanicaTD from '../../Mecanica/MecanicaTD';
import MecanicaVJ from '../../Mecanica/MecanicaVJ';
import { RegulamentoContent } from '../../Regulamento';

// Services
import { UserUpdateModal } from '../../../components/UserUpdateForm';
import { UserUpdateHoldingModal } from '../../../components/UserUpdateHoldingForm';
import { UserUpdatePasswordForm } from '../../../components/UserUpdatePasswordForm';
import { useAuth } from '../../../contexts/AuthContext';
import { useCampaign } from '../../../contexts/CampaignContext';
import { useLockedModal } from '../../../contexts/LockedModalContext';
import { useModal } from '../../../contexts/ModalContext';
import api from '../../../services/api';
import * as S from "./style";

export default function Activation() {
    const { token } = useParams();
    const navigate = useNavigate();
    const { campaignData } = useCampaign()
    const { userCan, user, setAvatar } = useAuth()
    const { openModal, closeModal } = useModal();
    const { openModal: openLockedModal, closeModal: closeLocked } = useLockedModal();

    const handleConfirmTerm = useCallback(async () => {
        closeLocked();
        try {
            const response = await api.post(`/activate/${token.replace("token=", '')}`)
            toast.success(response.data.message, {
                theme: "colored"
            });

            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data.user));

            navigate('/home');
        } catch (err) {
            console.log(err);
            toast.error(err.response.data?.message, {
                theme: "colored"
            });
        }
    }, [closeLocked, navigate, token]);

    const handleAvatarUpload = useCallback(async (img) => {
        try {
            const response = await api.patch(`/me/avatar`, { avatarId: img.image.id });
            setAvatar(response.data.avatar.path)
            handleConfirmTerm();
        } catch (e) { }
    }, [handleConfirmTerm, setAvatar]);

    const handleRejectTerm = useCallback(async () => {
        try {
            const response = await api.get(`/activate/${token.replace("token=", '')}/reject`)
            toast.success(response.data.message, {
                theme: "colored"
            });
            closeModal();
            navigate("/");
        } catch (err) {
            toast.error(err.response.data.message, {
                theme: "colored"
            });
        }
    }, [closeModal, navigate, token])

    const openImageUploadModal = useCallback(() => {
        if (user.avatar) {
            handleConfirmTerm();
            return;
        }
        closeLocked(() => {
            openLockedModal({
                header: 'Atualize seu avatar',
                size: 'small',
                body: <ImageUpload title="Trocar Avatar" text="110x110px tamanho minimo" onSend={handleAvatarUpload} error={'avatarError'} />
            })
        })
    }, [closeLocked, handleAvatarUpload, handleConfirmTerm, openLockedModal, user.avatar])

    const openUpdateUserModal = useCallback(async () => {
        closeModal(() => {
            openLockedModal({
                header: 'Atualize seus dados',
                size: 'large',
                body: <UserUpdateModal onSave={openImageUploadModal} />
            })
        })
    }, [closeModal, openImageUploadModal, openLockedModal]);

    const openUpdateUserLockedModal = useCallback(async () => {
        closeLocked(() => {
            openLockedModal({
                header: 'Atualize seus dados',
                size: 'large',
                body: <UserUpdateModal onSave={openImageUploadModal} />
            })
        })
    }, [closeLocked, openImageUploadModal, openLockedModal]);

    const openUpdateHoldingModal = useCallback(() => {
        if (!userCan("holdings:update")) {
            openUpdateUserModal()
            return
        }
        closeModal(() => {
            openLockedModal({
                header: 'Atualize os dados da sua rede',
                body: <UserUpdateHoldingModal onSave={openUpdateUserLockedModal} />
            })
        })
    }, [userCan, closeModal, openUpdateUserModal, openLockedModal, openUpdateUserLockedModal]);

    const openUpdatePasswordModal = useCallback(() => {
        closeModal(() => {
            openModal({
                header: 'Atualize sua senha',
                body: <UserUpdatePasswordForm onSave={openUpdateHoldingModal} />
            })
        })
    }, [closeModal, openModal, openUpdateHoldingModal]);

    const openAcceptModal = useCallback(() => {
        openModal({
            header: <span style={{ color: '#CC0000' }}>Atenção! o prazo de aceite para a ONDA 4 já expirou.</span>,
            body: <>
                <p>Você deseja acessar mesmo sem estar concorrendo?</p>
                <S.ButtonContainer>
                    <button className="button secondary" onClick={() => closeModal()}>Não aceito</button>
                    <button className="button secondary" onClick={openUpdatePasswordModal}>Aceito</button>
                </S.ButtonContainer>
            </>
        });
    }, [openModal, openUpdatePasswordModal, closeModal]);

    const openRejectModal = useCallback(() => {
        openModal({
            header: 'Você tem certeza?',
            body: <>
                <p>Você tem certeza que NÃO deseja participar da campanha {campaignData.title}?</p>
                <S.ButtonContainer>
                    <button className="button secondary" onClick={handleRejectTerm}>Recusar</button>
                    <button className="button secondary" onClick={() => closeModal()}>Voltar</button>
                </S.ButtonContainer>
            </>
        });
    }, [openModal, campaignData, handleRejectTerm, closeModal]);

    return <S.Container className="landing">
        <div className="text-center mb-5">
            <h1>Regulamento</h1>
        </div>

        <RegulamentoContent channelName={user.channel.name} />
        {user.channel.name === 'MERCHAN' && <MecanicaMerchan />}
        {user.channel.name !== 'MERCHAN' && <>
            <MecanicaCC />
            <MecanicaGKA />
            <MecanicaLMT />
            <MecanicaFDF />
            <MecanicaVJ />
            <MecanicaTD />
            <MecanicaHIB />
            <MecanicaATC />
        </>}

        <div className="form-row" style={{ flexDirection: 'row', justifyContent: "space-between" }}>
            <S.BtnGroup>
                <div className="form-item" >
                    <button className="button medium secondary mt-5" type="text" onClick={openRejectModal}>
                        Recuso o regulamento
                    </button>
                </div>

                <div className="form-item" >
                    <button className="button medium secondary mt-5" type="text" onClick={openAcceptModal}>
                        Aceito o regulamento
                    </button>
                </div>
            </S.BtnGroup>
        </div>
    </S.Container>
}