import { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import api from '../../../services/api'
import Template from '../../../template'
import * as S from './style'

export default function Reports(props) {
  const { userCan } = useAuth()
  const [quizzes, setQuizzes] = useState([])
  const handleDownloadStatusRedeReport = useCallback(async () => {
    const response = await api.get('/user-status/export', {
      responseType: 'blob',
    })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'user-status.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])
  const handleDownloadAcceptBoost = useCallback(async () => {
    const response = await api.get('/accept-boost/export', {
      responseType: 'blob',
    })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'user-status.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])
  const handleDownloadMetasReport = useCallback(async (stepId) => {
    const response = await api.get(`/metas/export`, {
      params: { stepId },
      responseType: 'blob',
    })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'metas.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])
  const handleDownloadRankingReport = useCallback(async () => {
    const response = await api.get(`/ranking/export`, {
      responseType: 'blob',
    })
    console.log(response)
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'ranking.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])
  const handleDownloadRankingHomologReport = useCallback(async () => {
    const response = await api.get(`/ranking-homolog/export`, {
      responseType: 'blob',
    })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'ranking-homolog.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])
  const handleDownloadResponsaveisReport = useCallback(async () => {
    const response = await api.get(`/responsaveis-legais/export`, {
      responseType: 'blob',
    })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'responsaveis-legais.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])
  const handleDownloadUserReport = useCallback(async (stepId) => {
    const response = await api.get('/accept-log', {
      responseType: 'blob',
    })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'fotos.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])

  const loadReportData = useCallback(async () => {
    const response = await api.get('/dashboard/reports')
    setQuizzes(response.data.quizzes)
  }, [])

  const handleDownloadQuizRankling = useCallback(async (quizId) => {
    const response = await api.get(`/report/quizRanking/${quizId}`, {
      responseType: 'blob',
    })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `ranking-quiz.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])

  useEffect(() => {
    loadReportData()
  }, [loadReportData])
  return (
    <>
      <Template>
        <div className="grid medium-space">
          <div className="account-hub-content">
            <S.Grid>
              <div className="grid-column">
                <S.GridFastAcccess>
                  {userCan('photo:export') && <S.FastLink className="product-category-box category-all" style={{ background: `linear-gradient(90deg,var(--primary),var(--secondary))` }} onClick={handleDownloadUserReport}>
                    <p className="product-category-box-title">Exportar Fotos</p>
                  </S.FastLink>}

                  {userCan('user-status:export') && <S.FastLink className="product-category-box category-all" style={{ background: `linear-gradient(90deg,var(--primary),var(--secondary))` }} onClick={handleDownloadStatusRedeReport}>
                    <p className="product-category-box-title">Exportar usuários</p>
                  </S.FastLink>}

                  {userCan('user-status:export') && <S.FastLink className="product-category-box category-all" style={{ background: `linear-gradient(90deg,var(--primary),var(--secondary))` }} onClick={handleDownloadAcceptBoost}>
                    <p className="product-category-box-title">Exportar aceite mecanica Boost</p>
                  </S.FastLink>}

                  {userCan('metas-all:export') && <S.FastLink className="product-category-box category-all" style={{ background: `linear-gradient(90deg,var(--primary),var(--secondary))` }} onClick={() => handleDownloadMetasReport(1)}>
                    <p className="product-category-box-title">Metas etapa 1</p>
                  </S.FastLink>}

                  {userCan('metas-all:export') && <S.FastLink className="product-category-box category-all" style={{ background: `linear-gradient(90deg,var(--primary),var(--secondary))` }} onClick={() => handleDownloadMetasReport(2)}>
                    <p className="product-category-box-title">Metas etapa 2</p>
                  </S.FastLink>}

                  {userCan('ranking-all:export') && <S.FastLink className="product-category-box category-all" style={{ background: `linear-gradient(90deg,var(--primary),var(--secondary))` }} onClick={handleDownloadRankingReport}>
                    <p className="product-category-box-title">Ranking Ativo</p>
                  </S.FastLink>}

                  {userCan('ranking-preview-all:export') && <S.FastLink className="product-category-box category-all" style={{ background: `linear-gradient(90deg,var(--primary),var(--secondary))` }} onClick={handleDownloadRankingHomologReport}>
                    <p className="product-category-box-title">Ranking em Homologação</p>
                  </S.FastLink>}

                  {userCan('responsaveis-legais:read') && <S.FastLink className="product-category-box category-all" style={{ background: `linear-gradient(90deg,var(--primary),var(--secondary))` }} onClick={handleDownloadResponsaveisReport}>
                    <p className="product-category-box-title">Responsaveis legais</p>
                  </S.FastLink>}

                  {quizzes.map(q => <S.FastLink className="product-category-box category-all" style={{ background: `linear-gradient(90deg,var(--primary),var(--secondary))` }} onClick={() => handleDownloadQuizRankling(q.id)}>
                    <p className="product-category-box-title">Exportar ranking do quiz {q.title}</p>
                  </S.FastLink>)}
                </S.GridFastAcccess>
              </div>
            </S.Grid>
          </div>
        </div>
      </Template >
    </>
  )
}