import React from "react";
import { Link } from "react-router-dom"
import * as S from "./style";

export default function NotFound() {
    return (
        <S.ErrorPage>
            <S.ErrorSectionContainer className="error-section">
                <p className="error-section-title">404</p>

                <div className="error-section-info">

                    <p className="error-section-subtitle">Oops!!...</p>

                    <p className="error-section-text">Caso o erro continue, por favor, fale com nosso suporte
                        <Link to="/forums">&nbsp;contato@gigantedaexecucao.com.br</Link>
                    </p>

                    <Link className="button medium primary" to="/home">Voltar ao início</Link>
                </div>
            </S.ErrorSectionContainer>
            <S.BottomDeco />
        </S.ErrorPage>
    )
}