import { Link as RRDLink } from 'react-router-dom'
import styled from "styled-components"

export const Container = styled.div.attrs(() => {
  return {
    className: "js-ranking-selector-container"
  }
})`
  display: flex;
  flex-direction: column;
  max-width: 1180px;
  margin: 0 auto;
`

export const Tabs = styled.ul`
  background-color: #FFF;
  border-radius: 100px;
  box-shadow: 4px 4px 8px #00000008;
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  margin-bottom: 20px;
  position: relative;
  width: 100%;

  &:before {
    background: var(--primary);
    background: linear-gradient(225deg, var(--primary) 0%, #3e1e83 100%);
    bottom: 0;
    border-radius: 100px;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    transform: translateX(${({ step }) => `${116 * step}px`});
    transition: 200ms all;
    transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
    top: 0;
    width: 100px;
    z-index: 1;
  }

  li {
    color: #000;
    flex: 0 100px;
    padding: 16px;
    position: relative;
    transition: inherit;
    transition-timing-function: inherit;
    text-align: center;
    z-index: 2;

    &:nth-child(${({ step }) => step + 1}) {
      color: #FFF;
    }
  }
`

export const Content = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
`

export const Image = styled.img`
  display: block;
  max-width: 100%;
  width: auto;
`

export const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  position: relative;

  &.ativo {
    &::after {
      background-color: #008800;
      border-radius: 4px 0 0 4px;
      color: #ffffff;
      content: "Ativa";
      display: block;
      font-weight: bold;
      padding: 4px 4px 4px 8px;
      position: absolute;
      right: 0;
      top: 15px;
    }
  }

  p {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Link = styled(RRDLink).attrs(() => {
  return {
    className: 'button primary'
  }
})`
  width: 100%;
`