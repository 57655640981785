import { Link } from 'react-router-dom'
import ligaLogo from '../../assets/img/logo-liga.png'
import * as S from './style'

function FooterComponent() {
  return <S.Footer>
    <img src={ligaLogo} alt="logo liga gigantes da execução" />
    <div className="footer-links">
      <a href="https://privacyportal-de.onetrust.com/webform/f69c0bf1-10ab-4d33-8b59-e235ddd37a5f/42251e9d-f6be-4ae0-971c-f311d8047e5a" target="_blank" rel="noreferrer">Requerimentos sobre Privacidade de Dados</a>
      <a href="https://img.casadebraganca.com/gigantes-2023/documentos/Aviso%20de%20privacidade%20-%20Mondelez.pdf" target="_blank" rel="noreferrer">Aviso de Privacidade</a>
      <Link to="/regulamento">Regulamento</Link>
      <Link to="/terms">Termos de uso</Link>
    </div>
  </S.Footer>
}

export default FooterComponent
