import styled from 'styled-components';

export const Pergunta = styled.div`
  text-align: center;
  h4{
    margin-bottom: 20px;
  }
`

export const BtnAlt = styled.button`
  border-radius: 0;
  border: 1px solid #dedeea;
  color: #3e3f5e;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 12px 0;
  &:hover {
    color: #fff;
    background-color: #5753e4;
  }
  &:focus {
    outline: none;
  }
  &.active {
    background-color: #5753e4;
    color: #fff;
  }
`