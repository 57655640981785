import Step from './Step'
import * as S from './style'

import geShield from '../../../assets/img/regulamento/ge-shield.png'

export default function SectionRanking () {
  return <S.RankingSectionContainer>
    <img src={geShield} alt="" />

    <S.Divider />

    <Step
      description="ACUMULE ESCUDOS POR KPI! QUANTO MAIS ESCUDOS MAIS CHANCES DE GANHAR"
      header
      title="COMO PARTICIPAR"
    />

    <S.Divider />

    <Step
      description="RANKING POR CANAL E CARGO"
      title="PREMIAÇÃO"
    />

    <S.Divider />

    <Step
      description="MC1.VISOR SCANNTECH E BI. MDLZ"
      title="FONTE"
    />

    <S.Divider />

    <Step
      brands
      description="VALE MAIS, GANHE ESCUDOS BÔNUS!"
      title="MARCA OURO"
    />

    <S.Divider />

    <Step
      description="LOJAS 5 ESTRELAS MSL, SKU POR PDV E POSITIVAÇÃO"
      text="Além da marca ouro e marcas foco, você também poderá acumular pontos atingindo o target total MDLZ."
      title="KPI´S"
    />

  <S.Divider />
  </S.RankingSectionContainer>
}
