import { useCallback, useEffect, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import homeStars from '../../assets/img/home-stars.png';
import platinumShield from '../../assets/img/platinum-rank.png';
import api from "../../services/api";
import HexagonComponent from "../Hexagon";
import * as S from "./style";

export default function BannerTitleHome({ avatar, username, position, stars }) {
    const [banners, setBanners] = useState()

    const loadBanners = useCallback(async () => {
        const response = await api.get(`/banner/`)
        setBanners(response.data.banners)
    }, [])

    useEffect(() => {
        loadBanners()
    }, [loadBanners])

    if (!banners) return '...'

    return (
        <S.BannerContainer className="section-banner profile-banner">
            <S.SliderHome>
                <Carousel showThumbs={false} showArrows={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={5000}>
                    {banners.map((banner, index) => <div key={index}>
                        <S.Anchor href={banner.link}>
                            <img src={banner.path} alt="BannerHome" />
                        </S.Anchor>
                    </div>)}
                </Carousel>
            </S.SliderHome>

            <S.AvatarContainer>
                <div className="home-user-info">
                    <div className="left">
                        <div className="hexagon-container">
                            <HexagonComponent src={avatar} />
                        </div>
                        <div className="greeting-user">
                            <p>{username}</p>
                            <p className="form-link">SEJA BEM VINDO!</p>
                        </div>
                    </div>
                    <div className="right">
                        <div className="group">
                            <div className="user-info-container">
                                <p class="text-mmedium">Resultado parcial da ONDA 4</p>
                            </div>
                        </div>

                        <div className="line-deco" aria-hidden="true" />

                        <div className="group">
                            <img className="platinum-shield" src={platinumShield} alt="escudo platinum" />
                            <div className="line-deco" aria-hidden="true" />
                            <div className="user-info-container">
                                <p>{position}</p>
                                <p className="text-small">POSIÇÃO</p>
                            </div>
                        </div>

                        <div className="line-deco" aria-hidden="true" />

                        <div className="group">
                            <img className="stars-shield" src={homeStars} alt="escudo estrelas" />
                            <div className="line-deco" aria-hidden="true" />
                            <div className="user-info-container">
                                <p>{stars}</p>
                                <p className="text-small">ESCUDOS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </S.AvatarContainer>

            <div style={{ clear: 'both' }}></div>
        </S.BannerContainer>
    )
}