import styled from "styled-components";

export const FlowCardContainer = styled.div`
  background: #FFF;
  border-radius: 0.75rem;
  box-shadow: 0px 0px 22.89063px 0px #E1E4ED;
  height: 14.5rem;
  padding: 0.625rem;
  position: relative;
  width: 100%;
  max-width: 20.5rem;

  h2, p {
    font-feature-settings: 'clig' off, 'liga' off;
    font-weight: 400;
  }

  h2 {
    color: #554D89;
    font-family: MDLZBiteType, sans-serif;
    font-size: clamp(3rem, 2.8274rem + 0.7673vw, 3.75rem);
    line-height: clamp(3.5rem, 3.4425rem + 0.2558vw, 3.75rem);
    letter-spacing: -0.2504rem;
    margin-bottom: 1rem;
  }

  p {
    color: #716C80;
    font-family: Montserrat, sans-serif;
    font-size: clamp(1.125rem, 1.0387rem + 0.3836vw, 1.5rem);
    line-height: clamp(1.5rem, 1.3849rem + 0.5115vw, 2rem);
  }

  > img {
    bottom: -1rem;
    position: absolute;
    right: -6rem;
    z-index: 1;

    @media screen and (max-width: 1366px) {
      bottom: -4rem;
      right: -4rem;
      transform: rotate(90deg);
    }
  }
`