import * as S from './style'

const STEP_MONTHS = {
  1: 'Fev, Mar e Abr',
  2: 'Mai, Jun',
  3: 'Jul, Ago e Set',
  4: 'Out, Nov e Dez'
}

export default function WavesTimelineStep ({ active, step }) {
  return <S.WavesTimelineStepContainer className={active && 'active'}>
    <p>
      Onda
      <span>{step}</span>
      <span>{STEP_MONTHS[step]}</span>
    </p>
  </S.WavesTimelineStepContainer>
}
