import styled from "styled-components"
import escudo from '../../../assets/img/mecanicas/step-2/escudo.png'
import vertical from '../../../assets/img/mecanicas/step-2/vertical.png'

const textColor = '#ED7D31'
const bgColor = `${textColor}4D`

export const Container = styled.div`
  background: linear-gradient(90deg, #330050 0%, #B93277 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0 50px 197px 50px;

  h3 {
    color: ${textColor};
    text-align: center;
    font-family: Montserrat;
    font-size: clamp(0.875rem, 0.8462rem + 0.1279vw, 1rem);
    font-style: normal;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    color: #FFF;
    font-family: Montserrat;
    font-size: 12px;
    margin-bottom: 12px;
  }
`

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  @media screen and (max-width: 560px) {
    flex-direction: column;
    gap: 0;
  }
`

export const TitleLogo = styled.div`
  flex: 1 38.26%;
`

export const Title = styled.div`
  flex: 1 calc(100% - 38.26%);
`

export const KPITitle = styled.h1`
  color: ${textColor};
  font-family: Montserrat;
  font-size: clamp(1.75rem, 1.5774rem + 0.7673vw, 2.5rem);
  font-weight: 900;
`

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 484px);
  justify-content: center;
  padding-top: 10px;
  width: 100%;

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, 100%);
  }
`

export const Card = styled.div.attrs(() => {
  return {
    className: 'js-card'
  }
})`
  background-color: ${bgColor};
  border-radius: 33px;
  color: #FFF;
  display: flex;
  flex: 0 484px;
  flex-direction: column;
  padding: 20px;

  h2 {
    color: ${textColor};
    font-family: Montserrat;
    font-size: clamp(2rem, 1.8274rem + 0.7673vw, 2.75rem);
    font-weight: 900;
  }

  h3 {
    text-align: center;
  }

  hr {
    border-bottom: 1px solid #FFF;
    border-top: 0 none;
    margin: 0;
  }
`

export const CardTitle = styled.div`
  margin-bottom: 0.5rem;
  white-space: nowrap;
`

export const KPIContainer = styled.div.attrs(() => {
  return {
    className: 'js-kpi-container'
  }
})`
  display: flex;
  justify-content: space-around;
  padding-bottom: 25px;
  padding-top: 25px;

  p {
    font-size: clamp(1rem, 0.8849rem + 0.5115vw, 1.5rem);
  }

  @media screen and (max-width: 520px) {
    gap: 20px;
    flex-direction: column;
  }
`

export const KPIText = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Logos = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  div {
    flex: 1 100%;

    img {
      display: block;
      height: auto;
      margin-top: 4px;
      max-width: 100%;
    }
  }
`

export const InfoText = styled.div`
  align-items: center;
  color: #FFF;
  display: flex;
  text-align: center;
  font-family: Montserrat;
  font-size: clamp(0.75rem, 0.7212rem + 0.1279vw, 0.875rem);
  font-style: normal;
  font-weight: 900;
  justify-content: center;
  line-height: 16px;
`

export const TotalContainer = styled.div`
  align-items: center;
  color: #FFF;
  display: flex;
  gap: 46px;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 29px;
  padding-bottom: 29px;

  @media (max-width: 520px) {
    flex-direction: column;
  }

  p {
    color: inherit;
    text-align: center;
    font-family: Montserrat;
    font-size: clamp(0.75rem, 0.6925rem + 0.2558vw, 1rem);
    font-weight: 900;
    margin: 0;
  }

  & > div {
    background-color: ${textColor};
    font-family: Montserrat;
    font-size: clamp(1rem, 0.9425rem + 0.2558vw, 1.25rem);
    font-weight: 900;
    padding: 8px 51px 8px 11px;
    position: relative;
  }
`

export const Escudo = styled.img.attrs(() => {
  return {
    src: escudo
  }
})`
  height: auto;
  width: 100%;
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translate(50%, -50%);
  max-width: 84px;
`

export const Exemplo = styled.div`
  p {
    line-height: 13px;
  }
`

export const TituloQuem = styled.img`
  display: block;
  height: auto;
  margin-top: 32px;
  max-width: 100%;
`

export const Name = styled.div`
  color: #FFF;
  font-family: Montserrat;
  font-size: clamp(2rem, 1.6547rem + 1.5345vw, 3.5rem);
  font-style: normal;
  font-weight: 900;
  margin-top: 29px;
`

export const TableContainer = styled.div`
  overflow-x: auto;
  padding-bottom: 1rem;
  width: 100%;

  ::-webkit-scrollbar {
    height: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${textColor};
    border-radius: 12px;

    :hover {
      background: ${bgColor};
    }
  }
`

export const Table = styled.table`
  max-width: 561px;
  width: 100%;

  td, th {
    font-family: Montserrat;
    font-size: clamp(0.75rem, 0.6349rem + 0.5115vw, 1.25rem);
    font-weight: 900;
    text-align: center;
    padding: 1rem;

    @media (max-width: 1366px) {
      &:has(img) {
        padding: 0.5rem;
      }

      &:not(:has(img)) {
        padding: 0;
      }
    }
  }

  th {
    color: ${textColor};
  }

  td {
    color: #FFF;
  }
`

export const Vertical = styled.img.attrs(() => {
  return {
    src: vertical
  }
})`
  max-width: 1px;
`

export const HeroContainer = styled.div`
  height: auto;
  max-width: 225px;

  img {
    width: 100%;
  }
`

export const HeroiTable = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  @media (max-width: 1096px) {
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }
`