import { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import InputText from "../Forms/InputText";
import CargoComponent from "../CargoComponent";
import api from "../../services/api";
import { MaskCEP, MaskDocument, MaskPhone } from '../../utils/mask'
import { FormValidator } from "../../domain/FormValidator"
import RepublicaGetAddress from '../../domain/RepublicaGetAddress'
import { useAuth } from "../../contexts/AuthContext";
import schema from '../../config/userFormScheme.json'
import schemaAddress from '../../config/userFormAddressScheme.json'
import * as S from './style'

const formValidator = new FormValidator(schema);
const formValidatorAddress = new FormValidator(schemaAddress);
const republicaGetAddress = new RepublicaGetAddress();

export function UserUpdateModal({ onSave }) {
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState({});
  const { userCan } = useAuth()
  const handleSave = useCallback(async (evt) => {
    evt.preventDefault();
    let hasError = false
    if (!formValidator.validate(user)) {
      setErrors(formValidator.errors)
      hasError = true
    }
    if (userCan('addresses:update')) {
      if (!formValidatorAddress.validate(user)) {
        setErrors({ ...errors, ...formValidatorAddress.errors })
        hasError = true
      }
    }
    if (hasError) return
    try {
      await api.put(`/user/${user.id}`, user);
      onSave();
    } catch (err) {
      if (!err.response) {
        console.log(err);
        return;
      }
      switch (err.response.statuCode) {
        case 403:
          break;
        case 400:
          toast.error(err.response.data, { theme: 'colored' });
          break;
        default:
          toast.error('Ocorreu um erro interno no servidor, por favor tente novamente', { theme: 'colored' });
          break;
      }
    }
  }, [user, userCan, errors, onSave]);

  const loadMe = useCallback(async () => {
    const response = await api.get(`/me`);
    setUser({
      ...response.data.address,
      ...response.data
    });
  }, []);

  const handleUpdateCep = useCallback(async (cep) => {
    setUser((user) => ({ ...user, cep }))
    if (cep.length === 9) {
      const { uf: estado, cidade, logradouro, bairro } = await republicaGetAddress.getAddress(cep)
      setUser((user) => ({ ...user, estado, cidade, logradouro, bairro }))
    }
  }, [])

  useEffect(() => {
    loadMe();
  }, [loadMe]);

  return <form onSubmit={handleSave}>
    <S.Row>
      <InputText name="name" label="Nome completo" value={user.name || ""} onChange={(name) => setUser({ ...user, name })} error={errors.name} />
    </S.Row>
    <S.Row>
      <InputText name="email" label="E-mail corporativo" value={user.email || ""} readonly={true} />
      <InputText name="birthday" label="Data de nascimento" value={user.birthday || ""} onChange={(birthday) => setUser({ ...user, birthday })} error={errors.birthday} type="date" />
    </S.Row>
    <S.Row>
      <InputText name="cellphone" label="Telefone Corporativo" value={user.cellphone || ""} onChange={(cellphone) => setUser({ ...user, cellphone })} error={errors.cellphone} mask={MaskPhone} />
      <InputText placeholder="" name="document" label="CPF" value={user.document || ""} onChange={(document) => setUser({ ...user, document })} error={errors.document} mask={MaskDocument} />
    </S.Row>
    <S.Row>
      <div className="form-input">
        <CargoComponent value={user.cargo || ""} onChange={(cargo) => setUser({ ...user, cargo })} error={errors.cargo} />
      </div>
      {userCan("addresses:update") && <InputText name="cep" label="CEP" value={user.cep || ""} onChange={handleUpdateCep} error={errors.cep} mask={MaskCEP} />}
    </S.Row>
    {userCan("addresses:update") && <>
      <S.Row>
        <InputText name="estado" label="Estado" value={user.estado || ""} onChange={(estado) => setUser({ ...user, estado })} error={errors.estado} />
        <InputText name="cidade" label="Cidade" value={user.cidade || ""} onChange={(cidade) => setUser({ ...user, cidade })} error={errors.cidade} />
      </S.Row>
      <S.Row>
        <InputText name="bairro" label="Bairro" value={user.bairro || ""} onChange={(bairro) => setUser({ ...user, bairro })} error={errors.bairro} />
        <InputText name="logradouro" label="Rua" value={user.logradouro || ""} onChange={(logradouro) => setUser({ ...user, logradouro })} error={errors.logradouro} />
      </S.Row>
      <S.Row>
        <InputText name="numero" label="Numero" value={user.numero || ""} onChange={(numero) => setUser({ ...user, numero })} error={errors.numero} />
        <InputText name="complemento" label="Complemento" value={user.complemento || ""} onChange={(complemento) => setUser({ ...user, complemento })} error={errors.complemento} />
      </S.Row>
    </>}
    <button type="submit" className="button secondary">Salvar</button>
  </form>
}