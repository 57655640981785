import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import AccountHubIcon from '../../assets/img/banner/icon-quiz.png';
import BannerTitleComponent from "../../components/BannerTitle";
import InputButton from "../../components/InputButton";
import PageLoaderComponent from "../../components/PageLoader";
import { useAuth } from "../../contexts/AuthContext";
import api from "../../services/api";
import Template from "../../template";
import * as S from './style';

function QuizInterno(props) {
  const maxDuration = 600;
  const params = useParams()
  const { quizId } = params;
  const { token } = useAuth();
  const navigate = useNavigate();

  const [quiz, setQuiz] = useState();
  const [questions, setQuestions] = useState();
  const [currentQuestion, setCurrentQuestions] = useState();
  const [questionCount, setQuestionCount] = useState(1);
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [startTime, setStartTime] = useState();
  const [spentTime, setSpentTime] = useState();

  const [selectedAlt, setSelectedAlt] = useState();

  const loadData = useCallback(async () => {
    try {
      const response = await api.get(`/quiz/${quizId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      const quizData = response.data.quiz.shift();
      const newQuestions = [...quizData.questions].sort((a, b) => Math.random() - 0.5);
      const currQuest = newQuestions.shift()
      currQuest.alternatives = [...currQuest.alternatives].sort((a, b) => Math.random() - 0.5);
      setCurrentQuestions(currQuest)
      setIsLoadingQuestion(false)
      setQuestions(newQuestions);
      setQuiz(quizData);
      setStartTime(Date.now())
    } catch (err) {
      if (!err.response) {
        toast.error('Ocorreu um erro interno no servidor', { theme: "colored" })
        return;
      }

      toast.error(err.response.data.message, { theme: "colored" })
    }
  }, [quizId, token])

  const saveChoice = useCallback(async (alternative_id, question_id, time_spent) => {
    setIsLoadingQuestion(true)
    await api.post('/quiz/answer', {
      question_id,
      alternative_id,
      time_spent
    })

    if (questions.length === 0) {
      navigate(`/quiz/total/${quiz.id}`)
      return
    }

    setCurrentQuestions(questions.shift())
    setQuestionCount((value) => value + 1);
    setQuestions([...questions])
    setStartTime(Date.now())
    setIsLoadingQuestion(false)
    setIsSaving(false)
  }, [questions, navigate, quiz])

  const handleConfirm = useCallback(async () => {
    saveChoice(selectedAlt, currentQuestion.id, spentTime);
  }, [selectedAlt, currentQuestion, saveChoice, spentTime]);

  const saveNull = useCallback(() => {
    saveChoice(null, currentQuestion.id, maxDuration)
  }, [currentQuestion, saveChoice])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    const timer = setInterval(() => {
      const lapsedTime = Math.floor((Date.now() - startTime) / 100)
      console.log(lapsedTime, maxDuration);
      setSpentTime(lapsedTime)
      if (lapsedTime === maxDuration && !isSaving) {
        saveNull()
        setIsSaving(true)
      }
    }, 250)

    return () => clearInterval(timer)
  }, [startTime, isSaving, saveNull])

  if (!quiz) return <PageLoaderComponent />

  return <Template>
    <BannerTitleComponent
      imgUrl={AccountHubIcon}
      title="Quiz"
      subTitle={quiz.title}
      imgAlt="accounthub-icon"
    />
    <div className="grid medium-space">
      <div className="account-hub-content">

        <div className="grid-column">
          <div className="widget-box">

            <div className="widget-box-content">
              {!isLoadingQuestion ? <S.Pergunta>
                <h4>{questionCount} - {currentQuestion.title}</h4>

                {currentQuestion.alternatives.map(alt => <S.BtnAlt onClick={() => setSelectedAlt(alt.id)} className={selectedAlt === alt.id && 'active'}>{alt.title}</S.BtnAlt>)}
                <InputButton onClick={handleConfirm}>CONFIRMAR ESCOLHA</InputButton>
                <p>Tempo restante: {Math.floor((maxDuration - spentTime) / 10)} segundos</p>
              </S.Pergunta> : <p>Carregando próxima pergunta</p>}
            </div>
          </div>

        </div>
      </div>
    </div>

  </Template>;
}

export default QuizInterno;
