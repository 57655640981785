import exclamacao from '../../../assets/img/mecanicas/step-2/exclamacao.png'
import heroi from '../../../assets/img/mecanicas/step-2/heroi-azul.png'
import logoHeroi from '../../../assets/img/mecanicas/step-2/logo-heroi-azul.png'
import logoLacta from '../../../assets/img/mecanicas/step-2/logo-lacta.png'
import logoMondelez from '../../../assets/img/mecanicas/step-2/logo-mondelez.png'
import logoOreo from '../../../assets/img/mecanicas/step-2/logo-oreo.png'
import titulo2 from '../../../assets/img/mecanicas/step-2/titulo-azul.png'
import titulo from '../../../assets/img/mecanicas/step-2/titulo-gka.png'
import * as S from './styled'

function MecanicaGKA() {
  return <S.Container>
    <S.TitleContainer>
      <S.TitleLogo>
        <img src={logoHeroi} alt="" />
      </S.TitleLogo>
      <S.Title>
        <img src={titulo} alt="" />
      </S.Title>
    </S.TitleContainer>
    <S.CardContainer>
      <S.Card>
        <S.CardTitle>
          <h2>KPI 1</h2>
          <p>Lojas 4-5<br />Estrelas</p>
        </S.CardTitle>
        <hr />
        <S.KPIContainer>
          <S.KPIText>
            <h3>Total marcas</h3>
            <S.Logos>
              <div>
                <img src={logoMondelez} alt="" style={{ maxWidth: "84px" }} />
              </div>
            </S.Logos>
          </S.KPIText>
          <S.InfoText>
            Ralizado<br />vs Target<br />(peso 2)
          </S.InfoText>
        </S.KPIContainer>
        <hr />
        <S.EscudosContainer>
          <div>
            <h3>200 escudos</h3>
            <S.MiniEscudo />
          </div>
        </S.EscudosContainer>
      </S.Card>


      <S.Card>
        <S.CardTitle>
          <h2>KPI 2</h2>
          <p>MSL BOS</p>
        </S.CardTitle>
        <hr />
        <S.KPIContainer>
          <S.KPIText>
            <h3>Marca ouro</h3>
            <S.Logos>
              <div>
                <img src={logoOreo} alt="" style={{ maxWidth: "57px" }} />
              </div>
              <div>
                <img src={logoLacta} alt="" style={{ maxWidth: "57px" }} />
              </div>
            </S.Logos>
          </S.KPIText>
          <S.InfoText>
            Ralizado<br />(peso 1)
          </S.InfoText>
        </S.KPIContainer>
        <hr />
        <S.EscudosContainer>
          <div>
            <h3>50 escudos lacta</h3>
            <h3>50 escudos oreo</h3>
            <div>
              <h3>100 escudos</h3>
              <S.MiniEscudo />
            </div>
          </div>
        </S.EscudosContainer>
      </S.Card>
    </S.CardContainer>

    <S.TotalContainer>
      <p>PONTUAÇÃO<br />MÁXIMA</p>
      <div>
        TOTAL 300 ESCUDOS
        <S.Escudo />
      </div>
    </S.TotalContainer>
    <S.Exemplo>
      <h3>EXEMPLO</h3>
      <p><strong>KPI 1 (peso 2):</strong> Se o vendedor atende 10 lojas auditadas e dessas lojas 5 são classificadas como 4-5 estrelas, então o percentual de 4-5 estrelas é de 50%, logo o vendedor pontua 100 escudos (50 x 2 = 100)</p>
      <p><strong>KPI 2 (peso 1):</strong> Se o vendedor tiver 70% de MSL OREO (70 x 0,5 = 35) e 80% de MSL LACTA (80 x 0,5 = 40) = TOTAL dele será de 75 escudos.</p>
      <p><strong>FONTE DE APURAÇÃO: MC1<br />O target do Gerente de área será a média do target do seu time, assim como o resultado.</strong></p>
      <p><strong>Critério desempate:</strong> Maior % de atingimento lojas 4-5 estrelas</p>
    </S.Exemplo>
    <S.TituloQuem src={titulo2} alt="" />
    <S.Name>
      GKA
    </S.Name>

    <S.HeroiTable>
      <S.TableContainer>
        <S.Table>
          <tr>
            <th>CARGO</th>
            <td rowSpan={4}>
              <S.Vertical />
            </td>
            <th>QNTD GANHADORES</th>
            <td rowSpan={4}>
              <S.Vertical />
            </td>
            <th>PONTOS<br />ONDA 4</th>
          </tr>
          <tr>
            <td>EXECUTIVOS +<br />COORDENADORES</td>
            <td>4</td>
            <td rowSpan={2}>1000</td>
          </tr>
          <tr>
            <td>GERENTES DE ÁREA</td>
            <td>2</td>
          </tr>
        </S.Table>
      </S.TableContainer>
      <S.HeroContainer>
        <img src={heroi} alt="" />
      </S.HeroContainer>
    </S.HeroiTable>

    <S.Disclaimer>
      <img src={exclamacao} alt="" />
      <p>Caso o Gerente entre no Ranking de 2 Holdings diferentes, ele receberá apenas uma única premiação</p>
    </S.Disclaimer>
  </S.Container>
}

export default MecanicaGKA