import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from "../../contexts/AuthContext"
import Template from "../../template"
import MecanicaATC from './MecanicaATC'
import MecanicaCC from './MecanicaCC'
import MecanicaFDF from './MecanicaFDF'
import MecanicaGKA from './MecanicaGKA'
import MecanicaHIB from './MecanicaHIB'
import MecanicaLMT from './MecanicaLMT'
import MecanicaMerchan from './MecanicaMerchan'
import MecanicaTD from './MecanicaTD'
import MecanicaVJ from './MecanicaVJ'
import MecanicasSelectorComponent from './MecanicasSelectorComponent'
import * as S from './style'

function Mecanicas() {
  const { search } = useLocation();
  const { userCan, user } = useAuth()

  const channel = useMemo(() => {
    const channel = new URLSearchParams(search).get('channel')
    if (userCan('mechanic:read-all')) return channel
    return user.channel.name
  }, [search, user, userCan]);
  return (
    <>
      <Template>
        <S.Container>
          {userCan('mechanic:read-all') && !channel && <MecanicasSelectorComponent />}
          {channel === 'CC' && <MecanicaCC />}
          {channel === 'GKA' && <MecanicaGKA />}
          {channel === 'LMT' && <MecanicaLMT />}
          {channel === 'VJ' && <MecanicaVJ />}
          {channel === 'TD' && <MecanicaTD />}
          {channel === 'HIB' && <MecanicaHIB />}
          {channel === 'ATC' && <MecanicaATC />}
          {channel === 'FDF' && <MecanicaFDF />}
          {channel === 'MERCHAN' && <MecanicaMerchan />}
        </S.Container>
      </Template >
    </>
  )
}

export default Mecanicas