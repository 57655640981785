import axios, { isAxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import ChannelComponent from '../../components/ChannelComponent';
import InputSelect from '../../components/Forms/InputSelect';
import { useAuth } from '../../contexts/AuthContext';
import { useWorksheetDate } from '../../hooks/useWorksheetDate';
import api from '../../services/api';
import Template from '../../template';
import RankingGeral from './RankingGeral';
import * as S from './style';

let source;

function Ranking() {
  const [ranking, setRanking] = useState()
  const [stepId, setOnda] = useState()
  const [channelId, setChannelId] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [, setPeriod] = useState('')
  const [cargo, setCargo] = useState('gr')
  const { user, doLogout, userCan } = useAuth()
  const [channelName, setChannelName] = useState(user.channel.name)
  const [roles, setRoles] = useState()
  const load = useWorksheetDate()

  const loadPageData = useCallback(async () => {
    if (source) {
      source.cancel("Done")
    }
    setIsLoading(true)
    setRanking(null)
    try {
      source = axios.CancelToken.source()
      const results = await api.get(`/ranking`, { cancelToken: source.token, params: { stepId: stepId || 1, channelId } });
      setRanking(results.data)
      setPeriod(await load(stepId || 1))
      setChannelName(results.data.channel.name)
      setRoles(results.data.roles)
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          doLogout()
          return
        }
        toast.error('Ocorreu um erro inesperado, por favor tente novamente mais tarde!', { theme: 'colored' })
      }
    }
    setIsLoading(false)
  }, [stepId, channelId, load, doLogout]);

  const updateOnda = useCallback(async (onda) => {
    setOnda(onda);
  }, []);

  const myPosition = useMemo(() => {
    if (!ranking) return null
    if (!ranking[cargo]) return null
    if (!ranking[cargo].myPosition) return null
    return ranking[cargo].myPosition
  }, [ranking, cargo])

  const allRanking = useMemo(() => {
    if (!ranking) return null
    if (!ranking[cargo]) return null
    if (!ranking[cargo].ranking) return null
    return ranking[cargo].ranking
  }, [ranking, cargo])

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  return <Template>

    <div className="grid medium-space">
      <div className="account-hub-content">

        <div className="grid-column">
          <div className="widget-box">
            <>
              <S.FiltrosCargo className={userCan('ranking-all:read') && "large"}>
                <InputSelect label="Selecione uma onda" onChange={(evt) => updateOnda(evt)} large={true}>
                  <option value="1">Onda 1</option>
                  <option value="2">Onda 2</option>
                  <option value="3">Onda 3</option>
                  <option value="4">Onda 4</option>
                </InputSelect>
                {userCan('ranking-all:read') && <ChannelComponent onChange={(channelId) => setChannelId(channelId)} />}
                <InputSelect label="" onChange={(cargo) => setCargo(cargo)} large={true}>
                  <option value="">Selecione um cargo</option>
                  {roles && Object.keys(roles).map(key => <option value={key}>{roles[key]}</option>)}
                </InputSelect>
              </S.FiltrosCargo>
              {!isLoading && allRanking && <RankingGeral channel={channelName} onda={stepId} ranking={allRanking} myPosition={myPosition} canal={user.canal} />}
              {isLoading && <S.Loading>Carregando...</S.Loading>}
              {!isLoading && !allRanking && <h3 style={{ textAlign: "center", marginTop: "32px" }}>Não temos ranking para apresentar</h3>}
            </>
          </div>
          <p>* Para ser elegivel para a premiação você precisa de ao menos 1 escudo</p>
        </div>
      </div>
    </div>
  </Template>
}

export default Ranking;
