import styled from 'styled-components'

export const Footer = styled.footer`
  text-align: center;
  background: var(--primary);
  padding: 1.5rem;
  width: 100%;
  position: relative;
  z-index: 999999;
  display: flex;
  max-height: 140px;
  box-sizing: border-box;
  @media (max-width: 680px) {
    transform: translateX(0);
    max-width: 100%;
  }

  img {
    max-height: 90px;
    margin: 0 2rem 0 7rem;
    @media (max-width: 768px) {
      margin-left: 0;
      margin-right: 1rem;
    }
  }

  p {
    color: var(--white);
    line-height: 1.25rem;
  }
  div.footer-links {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    a {
      font-weight: 700;
      line-height: 1.5rem;
      color: #23d2e2;
      &:hover {
        color: #23d2e270;
      }
    }
  }
`