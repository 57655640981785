import styled from 'styled-components'

export const RankingSectionContainer = styled.section`
  padding: 2.75rem 0 4.75rem;
  position: relative;

  > img {
    position: absolute;
    top: 30%;
    width: 50%;

    @media screen and (max-width: 1565px) {
      width: 45%;
    }

    @media screen and (max-width: 1366px) {
      display: none !important;
    }
  }
`

export const Divider = styled.hr`
  border: 2px solid var(--white);
  margin: 0;
`

