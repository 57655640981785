import { useCallback, useEffect, useState } from 'react'
import api from '../../services/api'
import HexagonComponent from '../Hexagon'
import ProgressBar from '../ProgressBar'
import * as S from './style'

const Row = ({ rowData }) => {
  if (!rowData) return <></>
  return <tr>
    <td style={{ maxWidth: '120px' }}><HexagonComponent size="large" src={`${rowData.avatar}`} /></td>
    <td style={{ fontSize: '1rem' }}>{rowData.name}</td>
    <td>{rowData.points}</td>
    <td>{rowData.rank}</td>
    <td><ProgressBar current={rowData.ratio} max={100} /></td>
    <td>&nbsp;{rowData.ratio}%</td>
  </tr>
}

function QuizRankComponent({ id }) {
  const [ranking, setRanking] = useState();

  const loadRank = useCallback(async () => {
    const response = await api.get(`quiz/rank/${id}`)
    setRanking(response.data.rank)
  }, [id])

  useEffect(() => {
    loadRank();
  }, [loadRank])

  if (!ranking) return "carregando..."

  return <div className="widget-box">
    <p style={{ fontSize: '24px', padding: '2% 0%' }} className="widget-box-title">Top 5 do quiz</p>

    <S.Tabela>
      <table>
        <tr>
          <th>Jogador</th>
          <th></th>
          <th>Pontuação</th>
          <th>Posição</th>
          <th>Taxa de acerto</th>
        </tr>
        {ranking.map(r => <Row rowData={r} />)}
      </table>
    </S.Tabela>
  </div>
}

export default QuizRankComponent;
