import WavesTimelineStep from './WavesTimelineStep'
import * as S from './style'

import brands from '../../../assets/img/regulamento/brands.png'

export default function SectionWaves () {
  return <S.WavesSectionContainer>
    <S.WavesTimelineContainer>
      <WavesTimelineStep step="1" />
      <WavesTimelineStep step="2" />
      <WavesTimelineStep step="3" />
      <WavesTimelineStep active={true} step="4" />
    </S.WavesTimelineContainer>

    <S.WavesBrandsContainer>
      <img src={brands} alt="Oreo, Trident, Trident Max, Bis, Bis Extra" />
    </S.WavesBrandsContainer>

    <S.WavesTitleContainer>
      <h1>
        <span>A campanha
          <br />
          acontece de
        </span>
          <br />
          Fevereiro
          <br />
          a Dezembro
          <br />
          de
          {' '}
        <span>2023</span>
      </h1>
    </S.WavesTitleContainer>
  </S.WavesSectionContainer>
}
