import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/logo-liga.png'
import { useAuth } from '../../contexts/AuthContext'
import { useMenu } from '../../contexts/DesktopMenuContext'
import { useModal } from '../../contexts/ModalContext'
import { useClickOutside } from '../../hooks/useClickOutside'
import api from '../../services/api'
import MobileMenu from './MobileMenu'
import NoticeGroup from './NoticeGroup'
import * as S from "./style"

export default function Header() {
    const [activeSettings, setActiveSettings] = useState(false)
    const [wrapperRef] = useClickOutside(() => setActiveSettings(false))
    const { userCan, doLogout } = useAuth()
    const { setOpen } = useMenu()
    const { openModal } = useModal();

    const handleDownloadCetificate = useCallback(async (onda) => {
        try {
            const response = await api.get(`/certificate`, { params: { onda }, responseType: 'arraybuffer' })
            const blob = new Blob([response.data])
            console.log(blob);
            const anchor = document.createElement("a");
            const url = URL.createObjectURL(blob);
            anchor.href = url;
            anchor.download = `certificado-onda${onda}.pdf`;
            document.body.appendChild(anchor);
            anchor.click();
            setTimeout(function () {
                document.body.removeChild(anchor);
                window.URL.revokeObjectURL(url);
            }, 0);
        } catch (err) {
            openModal({
                body: <div className='form-row'>Você não tem certificado para baixar!<br /><br /></div>, title: 'Sinto muito'
            })
        }
    }, [openModal])

    const toggleSettings = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        setActiveSettings(!activeSettings)
    }, [activeSettings])

    const toggleMenu = useCallback(() => {
        setOpen(open => !open)
    }, [setOpen])

    return (
        <S.HeaderContainer>
            <header className="header">
                <div className="header-actions">
                    <MobileMenu />
                    <div className="sidemenu-trigger navigation-widget-trigger" onClick={toggleMenu}>
                        <svg className="icon-grid">
                            <use xlinkHref="#svg-grid"></use>
                        </svg>
                    </div>
                </div>
                <nav className="header-actions">
                    <div className="header-brand">
                        <div className="logo">
                            <Link to="/home">
                                <img src={logo} alt="Logo da campanha" style={{ maxHeight: '80px', marginTop: '0px' }} />
                            </Link>
                        </div>
                    </div>
                </nav>
                <div className="header-actions">
                    {userCan('newsfeed:read') && <div className="action-list">
                        <NoticeGroup />
                    </div>}

                    <div className="action-item-wrap">
                        <div className="action-item dark header-settings-dropdown-trigger" onClick={toggleSettings} >
                            <svg className="action-item-icon icon-settings">
                                <use href="#svg-settings"></use>
                            </svg>
                        </div>

                        <S.Dropbox active={activeSettings} className="dropdown-navigation header-settings-dropdown" ref={wrapperRef}>

                            <Link className="dropdown-navigation-link" to="/perfil">Meu perfil</Link>
                            <Link className="dropdown-navigation-link" to="/faq">Perguntas frequentes</Link>
                            <Link className="dropdown-navigation-link" to="/regulamento">Regulamento</Link>
                            <Link className="dropdown-navigation-link" to="/fale-conosco">Fale conosco</Link>
                            <Link className="dropdown-navigation-link" to="/terms">Termos de Uso</Link>

                            {userCan('download:certificado-1') && <S.DownloadButton class="dropdown-navigation-link" onClick={() => handleDownloadCetificate(1)}>Certificado da onda 1</S.DownloadButton>}
                            {userCan('download:certificado-2') && <S.DownloadButton class="dropdown-navigation-link" onClick={() => handleDownloadCetificate(2)}>Certificado da onda 2</S.DownloadButton>}
                            {userCan('download:certificado-3') && <S.DownloadButton class="dropdown-navigation-link" onClick={() => handleDownloadCetificate(3)}>Certificado da onda 3</S.DownloadButton>}
                            {userCan('download:certificado-4') && <S.DownloadButton class="dropdown-navigation-link" onClick={() => handleDownloadCetificate(4)}>Certificado da onda 4</S.DownloadButton>}

                            <S.LeaveButton
                                onClick={doLogout}
                                className="dropdown-navigation-button button small secondary"
                                type="button"
                            >
                                <i className="fas fa-door-closed"></i>
                                <span>Sair</span>
                            </S.LeaveButton>
                        </S.Dropbox>
                    </div>
                </div>
            </header>
        </S.HeaderContainer>
    )
}