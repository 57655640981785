import * as S from './style'

export default function SectionTerms () {
  return <S.TermsSectionContainer>
    <S.TermContainer>
      <h2>A Mondelez poderá:</h2>

      <ul>
        <li>Alterar a forma de apuração e/ou cancelar a Campanha, se a plataforma de auditoria falhar;</li>
        <li>Cancelar a Campanha e/ou a participação de Participantes, se houver fraude;</li>
        <li>Alterar a premiação e/ou cancelar a Campanha, se mudar sua política econômica e financeira durante a Campanha;</li>
        <li>Usar nomes e imagens dos Participantes para divulgação da Campanha em vídeos, internet e qualquer material de divulgação do Programa. Esta autorização, concedida pelos Participantes, é a título gratuito em território nacional e no exterior.</li>
        <li>Decidir sobre os casos em que as Condições Gerais não forem suficientes para solucionar. No silêncio da Mondelez, as ações diferentes das previstas não serão consideradas como novas obrigações.</li>
      </ul>
    </S.TermContainer>

    <S.TermContainer>
      <h2>Alteração da situação do empregado:</h2>

      <ul>
        <li>Em caso de alteração de cargo ou posição do participante, serão considerados os targets e resultados da função de maior período, não sendo possível aproveitar os resultados obtidos na função de menor período. Para o participante passando para nova função ou cargo que não esteja envolvida na campanha, este receberá forma proporcional ao atingimento de metas que possibilite a entrada no ranking de premiados até a movimentação de posição.</li>
        <li>Em caso de desligamento, o Participante receberá a premiação de forma proporcional ao atingimento de metas que possibilite a entrada no ranking de premiados até o momento do desligamento. Em caso de desligamento com justo motivo o empregado perderá o direito a premiação.</li>
        <li>O Participante que permanecer afastado durante toda a duração da Campanha não será elegível ao Programa, pois não haverá prestação de serviços;</li>
        <li>O Participante que permanecer afastado durante parte da duração da Campanha terá os pontos que obteve considerados proporcionalmente ao período efetivamente trabalhado.</li>
      </ul>
    </S.TermContainer>

    <S.TermContainer>
      <h2>Declarações do participante:</h2>

      <ul>
        <li>Que conhece e concorda com todas as regras das Condições Gerais e da Mecânica da Campanha;</li>
        <li>Que os contatos sobre a Campanha serão feitos através de seus e-mails, telefones corporativos, plataforma e site do Programa;</li>
        <li>Que a Campanha busca incentivar o aumento no Sell Out da Mondelez. No entanto, não existe nenhuma garantia de sucesso e de consequente premiação do Participante;</li>
        <li>Os pontos podem ser acumulados entre as ondas da campanha Gigantes da Execução 2023. O resgate dos pontos da campanha de 2023 deve ocorrer até dia 30 de maio de 2024. O saldo não é acumulativo com saldos de anos anteriores.</li>
        <li>Caso não aceite participar da campanha, o mesmo ficará inelegível ao prêmio, mesmo que atinja os targets.</li>
      </ul>
    </S.TermContainer>
  </S.TermsSectionContainer>
}
