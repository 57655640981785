import styled from "styled-components";

export const AwardedSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2.875rem clamp(0rem, -0.4028rem + 1.7903vw, 1.75rem);

  h1 {
    color: var(--white);
    font-family: MDLZBiteType, sans-serif;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 2.8125rem;
    line-height: 3.4063rem;
    letter-spacing: -0.2188rem;
  }
`
