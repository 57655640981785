import * as S from './style'

import heroes from '../../../assets/img/regulamento/SUPERS01.png'

export default function SectionRules () {
  return <S.RulesSectionContainer>
    <S.RulesSectionContent>
      <div>
        <h1>
          <span>VAMOS PARA AS
            <br />
            REGRAS GERAIS
          </span>
          <br />
          DA 4ª ONDA
          <br />
          <span>DOS GIGANTES</span>
          <br />
          2023
        </h1>
      </div>

      <div>
        <img src={heroes} alt="" />
      </div>
    </S.RulesSectionContent>
  </S.RulesSectionContainer>
}
