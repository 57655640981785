import { useContext, useState, useEffect, useCallback, createContext } from "react";
import { io } from 'socket.io-client';

const NotifyContext = createContext();

export default function NotifyProvider({ children }) {
  const [notices, setNotices] = useState([]);
  const token = localStorage.getItem('token');
  const [socket, setSocket] = useState(null);

  const handleFetchNotices = useCallback((messages) => {
    setNotices(messages);
  }, []);

  const handleNewNotice = useCallback((message) => {
    setNotices((notices) => [message, ...notices]);
  }, []);

  useEffect(() => {
    const ioServer = io.connect(process.env.REACT_APP_BASE_URL, {
      query: { token },
      transports: ["websocket"]
    });

    ioServer.on('fetch-notice', handleFetchNotices);
    ioServer.on('new-notice', handleNewNotice);

    setSocket(ioServer);

    return () => ioServer.close();
  }, [handleFetchNotices, handleNewNotice, token]);

  const markAllAsReady = useCallback(() => {
    const n = notices.map(not => ({ ...not, isRead: true }))
    setNotices(n)
    socket.emit('mark-as-read', n.map(not => not.id))
  }, [notices, socket]);

  return (
    <NotifyContext.Provider value={{ notices, markAllAsReady }}>
      {children}
    </NotifyContext.Provider>
  );
};

export function useNotify() {
  const context = useContext(NotifyContext)

  if (!context) throw new Error("useNotify must be used within a NotifyProvider");

  const setToken = () => console.log('setToken should not be used');

  const { notices, markAllAsReady } = context;

  return { notices, markAllAsReady, setToken };
}
