import styled from "styled-components"

export const Container = styled.div`
  aspect-ratio: 497 / 432;
  flex: 1 49%;
`

export const Canvas = styled.canvas`
  width: 100% !important;
  height: auto !important;
`

export const Title = styled.div.attrs(() => {
  return {
    className: 'js-widget-box-title'
  }
})`
  align-items: center;
  display: flex;
  justify-content: space-between;
  p {
    color: #212529;
    font-size: 1rem;
    font-weight: 700;
  }
`