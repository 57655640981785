import ConceptCard from './ConceptCard'
import * as S from './style'

import superHero from '../../../assets/img/regulamento/superhero.png'

export default function SectionConcepts () {
  return <S.ConceptsSectionContainer>

    <S.ConceptsCardsContainer>
      <h1>CONCEITOS</h1>
      <ConceptCard
        description="Estabelece as metas, prêmios, formas de pagamento e forma de apuração"
        title="mecânica"
      />

      <ConceptCard
        description="Período da campanha, podendo ser prorrogada ou interrompida."
        title="duração"
      />

      <ConceptCard
        description="Produtos produzidos pela Mondelēz das famílias indicadas."
        title="produtos"
      />

      <p>Essas informações estão indicadas no selling story da Campanha Gigantes da Execução (Anexo 1).</p>
    </S.ConceptsCardsContainer>

    <div>
      <img src={superHero} alt="" />
    </div>
  </S.ConceptsSectionContainer>
}
