import AwardTable from './AwardTable'
import * as S from './style'

export default function SectionAwarded ({ channelName }) {
  return <S.AwardedSectionContainer>
    <h1>Premiados: </h1>

    {channelName !== 'MERCHAN' && <>
      <AwardTable
        caption="GKA"
        executiveWinners="4"
        areaManagerWinners="2"
      />

      <AwardTable
        caption="LMT"
        executiveWinners="14"
        areaManagerWinners="4"
      />

      <AwardTable
        caption="DIA + FARMA + FOOD"
        executiveWinners="2"
        areaManagerWinners="1"
      />

      <AwardTable
        caption="C&C"
        executiveWinners="6"
        areaManagerWinners="2"
      />

      <AwardTable
        caption="ATACADO"
        executiveWinners="8"
        areaManagerWinners="4"
      />

      <AwardTable
        caption="TD"
        executiveWinners="10"
      />

      <AwardTable
        caption="TD + VJ"
        areaManagerWinners="4"
      />

      <AwardTable
        caption="VJ"
        executiveWinners="3"
      />

      <AwardTable
        caption="HÍBRIDO"
        executiveWinners="5"
        areaManagerWinners="2"
      />
    </>
  }

  {channelName === 'MERCHAN' && <AwardTable
    caption="MERCHANDISING"
    coordinatorWinners="33"
    managerWinners="3"
    promoterWinners="376"
  />}
  </S.AwardedSectionContainer>
}
