import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from "react-router-dom";
import DefaultAvatar from '../../assets/img/avatar.png';
import cover from '../../assets/img/banner-profile.png';
import HexagonComponent from '../../components/Hexagon';
import { useAuth } from '../../contexts/AuthContext';
import { useMenu } from '../../contexts/DesktopMenuContext';
import { useClickOutside } from '../../hooks/useClickOutside';
import api from '../../services/api';
import Premmiar from '../Premmiar';
import MenuButton from './MenuButton';
import * as S from "./style";

export default function SidebarComponent() {
  const [catalogUrl, setCatalogUrl] = useState()
  const [premmiar, setPremmiar] = useState(false)
  const [wrapperRef] = useClickOutside(function () { if (premmiar === true) setPremmiar(false) });

  const { open, menuItens } = useMenu()

  const { avatar, user, code, userCan } = useAuth();
  const Avatar = avatar ? avatar : DefaultAvatar;

  const name = useMemo(() => user ? user.name : "", [user]);

  const loadCatalog = useCallback(async () => {
    const response = await api.get('/premmiar')
    setCatalogUrl(response.data.catalogUrl)
  }, [])

  useEffect(() => {
    loadCatalog()
  }, [loadCatalog])

  if (!user) return <></>

  return (
    <S.SidebarContainer>
      <nav id="navigation-widget-small" className={`navigation-widget navigation-widget-desktop closed sidebar left ${open ? 'delayed' : 'hidden'}`}>
        <Link className="user-avatar small no-outline online" to="/perfil">
          <div className="user-avatar-content">
            <HexagonComponent src={Avatar} alt="Avatar" className="Avatar" />
          </div>

          <div className="user-avatar-progress">
            <div className="hexagon-progress-40-44"></div>
          </div>

          <div className="user-avatar-progress-border">
            <div className="hexagon-border-40-44"></div>
          </div>

          <div className="user-avatar-badge">
            <div className="user-avatar-badge-border">
              <div className="hexagon-22-24"></div>
            </div>

            <div className="user-avatar-badge-content">
              <div className="hexagon-dark-16-18"></div>
            </div>

            <p className="user-avatar-badge-text"></p>
          </div>
        </Link>
        <ul className="menu small">
          {menuItens && menuItens.map(m => <MenuButton title={false} {...m} />)}
        </ul>
      </nav>

      <nav id="navigation-widget" className={`navigation-widget navigation-widget-desktop sidebar left ${open ? 'hidden' : 'delayed'}`} data-simplebar>
        <figure className="navigation-widget-cover liquid">
          <img src={cover} alt="cover-01" style={{ objectFit: 'cover' }} />
        </figure>

        <S.ShortDesc className="user-short-description">
          <div style={{ width: '120px', margin: '0 auto' }}>
            <HexagonComponent src={avatar} size="large" />
          </div>

          <p className="user-short-description-title"><span>{name}</span></p>
          <p className="user-short-description-text"><span>&nbsp;</span></p>
        </S.ShortDesc>

        <ul className="menu">
          {menuItens && menuItens.map(m => <MenuButton key={m.name} {...m} />)}
          {userCan('access:gigantes-2022') && <li className={`menu-item`}>
            <a className="menu-item-link" href={`${process.env.REACT_APP_GIGATES2022}auth?code=${encodeURIComponent(code)}`}>
              <svg className="menu-item-link-icon icon-overview">
                <use href="#svg-overview"></use>
              </svg>
              Gigantes da Execução 2022
            </a>
          </li>}
          {userCan('access:premmiar-2022') && catalogUrl && <li className={`menu-item`}>
            <a className="menu-item-link" href={catalogUrl} target='_blank' rel="noreferrer">
              <svg className="menu-item-link-icon icon-overview">
                <use href="#svg-overview"></use>
              </svg>
              Catalogo de premios
            </a>
          </li>}
          {userCan('access:premmiar-2023') && <li className={`menu-item`}>
            <button className="menu-item-link" onClick={() => setPremmiar(true)}>
              <svg className="menu-item-link-icon icon-overview">
                <use href="#svg-overview"></use>
              </svg>
              Catalogo de premios
            </button>
          </li>}
          <li>&nbsp;</li>
        </ul>
      </nav>

      <Premmiar open={premmiar} wrapperRef={wrapperRef} />
    </S.SidebarContainer>
  )
}