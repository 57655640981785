import { useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import PageLoader from '../../components/PageLoader'
import api from '../../services/api'
import Template from '../../template'
import AccountStatBox from './AccountStatBox'
import DashboardChart from './DashboardChart'
import DashboardTable from './DashboardTable'
import Grid from './Grid'
import * as S from './styled'


function Resultados() {
  const [searchParams] = useSearchParams()
  const { userId } = useParams()
  const [data, setData] = useState()
  const [params, setParams] = useState({ stepId: searchParams.get('step') || 1, worksheetId: searchParams.get('worksheet') })

  const loadDashboardData = useCallback(async (params) => {
    const response = await api.get(`/result/${userId}`, { params })
    setData(response.data.content)
  }, [userId])

  const handleChangeFilter = useCallback((component, value) => {
    console.log(component, value)
    const obj = {}
    obj[component.attribute] = value
    setParams((params) => ({ ...params, ...obj }))
  }, [])

  useEffect(() => {
    loadDashboardData(params)
  }, [loadDashboardData, params])

  if (!data) return <PageLoader />

  return <Template>
    <Grid>
      {data.length ? data.map((component, index) => {
        switch (component.component) {
          case 'stats':
            return <AccountStatBox {...component}>
              {!component.icon && <S.Graph value={component.title} />}
            </AccountStatBox>
          case 'table':
            return <DashboardTable {...component} onChange={(value) => handleChangeFilter(component.filter, value)} />
          case 'chart':
            return <DashboardChart chartData={component} />
          case 'break':
            return <S.Break />
          default:
            return ''
        }
      }) : <h5>Não existe resultado para você</h5>}
    </Grid>
  </Template>
}

export default Resultados
