import { useEffect, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import Template from "../../template"
import PageLoaderComponent from "../../components/PageLoader";
import ProfileNav from "../../components/ProfileNav";
import { Outlet } from "react-router-dom";

export default function Perfil(props) {
  const [user, setUser] = useState({});

  const { data } = useFetch('/me');

  useEffect(() => {
    setUser(data);
  }, [data])

  if (!user) return <PageLoaderComponent />;

  return (
    <Template>
      <div style={{ marginTop: '8rem' }}></div>
      <ProfileNav />
      
      {user && <Outlet context={[user, setUser]}/>}
    </Template >
  )
}