import { useCallback, useState } from "react";
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import InputPassword from "../Forms/InputPassword";
import Checkbox from "../Forms/Checkbox";
import api from "../../services/api";
import { FormValidator } from "../../domain/FormValidator";
import schema from '../../config/passwordFormScheme.json';
import * as S from './style'
import { useCampaign } from "../../contexts/CampaignContext";

const formValidator = new FormValidator(schema);

export function UserUpdatePasswordForm({ onSave }) {
  const [password, setPassword] = useState({ newPassword: '', password: '', passwordConfirmation: '', acceptInfo: false, acceptTerms: false });
  const { campaignData } = useCampaign();
  const [errors, setErrors] = useState();

  const hasLowercase = useCallback((value) => {
    if (!value) return ''
    if (!value.match(/[a-z]/)) return ''
    if (value.match(/[a-z]/).length === 0) return ''
    return 'active'
  }, [])

  const hasUppercase = useCallback((value) => {
    if (!value) return ''
    if (!value.match(/[A-Z]/)) return ''
    if (value.match(/[A-Z]/).length === 0) return ''
    return 'active'
  }, [])

  const hasNumber = useCallback((value) => {
    if (!value) return ''
    if (!value.match(/[0-9]/)) return ''
    if (value.match(/[0-9]/).length === 0) return ''
    return 'active'
  }, [])

  const hasSpecial = useCallback((value) => {
    if (!value) return ''
    if (!value.match(/[!@#$%&*]/)) return ''
    if (value.match(/[!@#$%&*]/).length === 0) return ''
    return 'active'
  }, [])

  const handleSave = useCallback(async (evt) => {
    evt.preventDefault()
    if (!formValidator.validate(password)) {
      setErrors(formValidator.errors)
      return;
    }
    try {
      await api.patch(`/me/password`, password)
      onSave();
    } catch (err) {
      console.log(err)
      if (isAxiosError(err)) {
        if (err.statusCode !== 500) {
          toast.error(err.response.data.message, { theme: 'colored' })
          return
        }
      }
      toast.error('Ocorreu um erro interno no servidor.', { theme: 'colored' })
    }
  }, [onSave, password])

  return <form onSubmit={handleSave}>
    <S.Row>
      <InputPassword name="password" label="Senha" value={password.password || ""} onChange={(currentPassword) => setPassword({ ...password, password: currentPassword })} error={errors?.password} />
    </S.Row>
    <S.Row>
      <InputPassword name="newPassword" label="Nova Senha" value={password.newPassword || ""} onChange={(newPassword) => setPassword({ ...password, newPassword })} error={errors?.newPassword} />
    </S.Row>
    <S.Row>
      <S.CheckContainer>
        <S.Check className={hasLowercase(password.newPassword)} /> Letra minúscula
      </S.CheckContainer>
      <S.CheckContainer>
        <S.Check className={hasUppercase(password.newPassword)} /> Letra maiúscula
      </S.CheckContainer>
      <S.CheckContainer>
        <S.Check className={hasNumber(password.newPassword)} /> Numero
      </S.CheckContainer>
      <S.CheckContainer>
        <S.Check className={hasSpecial(password.newPassword)} /> Caractere especial
      </S.CheckContainer>
    </S.Row>
    <S.Row>
      <InputPassword name="password_confirmation" label="Confirmação de senha" value={password.passwordConfirmation || ""} onChange={(passwordConfirmation) => setPassword({ ...password, passwordConfirmation })} error={errors?.passwordConfirmation} />
    </S.Row>
    <S.Row>
      <Checkbox name="check1" text={<>
        Aceito os termos do <a href={campaignData?.privacyLink} target="_blank" rel="noreferrer">Aviso de Privacidade da Mondelez</a> e da <a href="/politica-de-uso" target="_blank">Plataforma</a>.
      </>} onChange={(acceptTerms) => setPassword({ ...password, acceptTerms })} error={errors?.acceptTerms} />
    </S.Row>
    <S.Row>
      <Checkbox name="check2" text={`Aceito receber informações sobre produtos, novidades, ofertas promocionais e marcas da Mondelez por e-mail, telefone, Whatsapp e SMS.`} onChange={(acceptInfo) => setPassword({ ...password, acceptInfo })} />
    </S.Row>

    <button type="submit" className="button secondary">Salvar</button>
  </form>
}