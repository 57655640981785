import styled from "styled-components";

export const AwardTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 0.125rem;
  text-transform: uppercase;

  caption {
    background: #7030A0;
    caption-side: top;
    color: var(--white);
    font-family: Inter, sans-serif;
    font-size: clamp(0.875rem, 0.5585rem + 1.4066vw, 2.25rem);
    font-weight: 700;
    line-height: clamp(1rem, 0.8849rem + 0.5115vw, 1.5rem);
    margin: 0;
    padding: clamp(0.75rem, 0.5774rem + 0.7673vw, 1.5rem) clamp(0.75rem, 0.6349rem + 0.5115vw, 1.25rem);
  }

  thead tr, tbody tr {
    background-color: var(--white);
  }

  thead, tbody {
    color: #000;
    font-family: Inter;
    font-size: clamp(0.5rem, 0.2123rem + 1.2788vw, 1.75rem);

    th, td {
      padding-inline: clamp(0.75rem, 0.6349rem + 0.5115vw, 1.25rem);
    }

    th {
      font-weight: 700;
      line-height: clamp(1rem, 0.8849rem + 0.5115vw, 1.5rem);
      height: clamp(0.75rem, 0.1170rem + 2.8133vw, 3.5rem);

      :not(:first-child) {
        text-align: center;
      }
    }

    tr {
      line-height: clamp(1rem, 0.8849rem + 0.5115vw, 1.5rem);
      height: clamp(0.75rem, 0.3472rem + 1.7903vw, 2.5rem);
    }

    td {
      :first-child {
        width: 50%;
      }

      :not(:first-child) {
        text-align: center;
      }
    }
  }
`