import { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import InputText from "../Forms/InputText";
import api from "../../services/api";
import { MaskCEP } from '../../utils/mask'
import { FormValidator } from "../../domain/FormValidator"
import RepublicaGetAddress from '../../domain/RepublicaGetAddress'
import schemaAddress from '../../config/userFormAddressScheme.json'
import * as S from './style'

const formValidatorAddress = new FormValidator(schemaAddress);
const republicaGetAddress = new RepublicaGetAddress();

export function AddressUpdateModal({ onSave }) {
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState({});
  const handleSave = useCallback(async (evt) => {
    evt.preventDefault();
    let hasError = false
    if (!formValidatorAddress.validate(user)) {
      setErrors({ ...errors, ...formValidatorAddress.errors })
      hasError = true
    }
    if (hasError) return
    try {
      await api.put(`/user/${user.id}`, user);
      console.log(onSave)
      onSave();
    } catch (err) {
      if (!err.response) {
        console.log(err);
        return;
      }
      switch (err.response.statuCode) {
        case 403:
          break;
        case 400:
          toast.error(err.response.data, { theme: 'colored' });
          break;
        default:
          toast.error('Ocorreu um erro interno no servidor, por favor tente novamente', { theme: 'colored' });
          break;
      }
    }
  }, [user, errors, onSave]);

  const loadMe = useCallback(async () => {
    const response = await api.get(`/me`);
    setUser({
      ...response.data.address,
      ...response.data
    });
  }, []);

  const handleUpdateCep = useCallback(async (cep) => {
    setUser((user) => ({ ...user, cep }))
    if (cep.length === 9) {
      const { uf: estado, cidade, logradouro, bairro } = await republicaGetAddress.getAddress(cep)
      setUser((user) => ({ ...user, estado, cidade, logradouro, bairro }))
    }
  }, [])

  useEffect(() => {
    loadMe();
  }, [loadMe]);

  return <form onSubmit={handleSave}>
    <S.Row>
      <InputText name="cep" label="CEP" value={user.cep || ""} onChange={handleUpdateCep} error={errors.cep} mask={MaskCEP} />
    </S.Row>
    <S.Row>
      <InputText name="estado" label="Estado" value={user.estado || ""} onChange={(estado) => setUser({ ...user, estado })} error={errors.estado} />
      <InputText name="cidade" label="Cidade" value={user.cidade || ""} onChange={(cidade) => setUser({ ...user, cidade })} error={errors.cidade} />
    </S.Row>
    <S.Row>
      <InputText name="bairro" label="Bairro" value={user.bairro || ""} onChange={(bairro) => setUser({ ...user, bairro })} error={errors.bairro} />
      <InputText name="logradouro" label="Rua" value={user.logradouro || ""} onChange={(logradouro) => setUser({ ...user, logradouro })} error={errors.logradouro} />
    </S.Row>
    <S.Row>
      <InputText name="numero" label="Numero" value={user.numero || ""} onChange={(numero) => setUser({ ...user, numero })} error={errors.numero} />
      <InputText name="complemento" label="Complemento" value={user.complemento || ""} onChange={(complemento) => setUser({ ...user, complemento })} error={errors.complemento} />
    </S.Row>
    <button type="submit" className="button secondary">Salvar</button>
  </form>
}