import styled from 'styled-components';

export const RegulamentoContainer = styled.div`
  background: linear-gradient(90deg, #330050 0%, #B93277 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

export const RegulamentoTitle = styled.div`
  align-items: center;
  background: linear-gradient(180deg, rgba(104, 82, 237, 0.05) 0%,rgb(186, 67, 252, 0.05) 100%);
  display: flex;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 12rem;
  justify-content: center;
  margin-inline: -1.75rem;
  padding-inline: 1.75rem;

  h1 {
    color: var(--white);
    font-family: MDLZBiteType, sans-serif;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: clamp(3rem, 2.8274rem + 0.7673vw, 3.75rem);
    letter-spacing: -0.2188rem;
    line-height: 3.4063rem;
    text-align: center;
    text-transform: uppercase;
  }
`
