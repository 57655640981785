import FlowCard from './FlowCard'
import * as S from './style'

import warning from '../../../assets/img/regulamento/warning.png'

export default function SectionCampaignFlow () {
  return <S.CampaignFlowSectionContainer>
    <h1>FLUXO DE CAMPANHA</h1>

    <S.FlowCardsContainer>
      <FlowCard
        hasArrow
        title="EXECUÇÃO"
      />

      <FlowCard
        hasArrow
        description="Avaliação do cumprimento das metas"
        title="APURAÇÃO"
      />

      <FlowCard
        hasArrow
        description="Realizada em 60 dias a contar do final da apuração*"
        title="DIVULGAÇÃO"
      />

      <FlowCard
        description="Realizada em 30 dias após a divulgação do resultado"
        title="PREMIAÇÃO"
      />
    </S.FlowCardsContainer>

    <S.WarningContainer>
      <img src={warning} alt="" />

      <p>Questionamentos sobre o resultado deverão ser feitos em até 10 dias da sua divulgação. Nesse caso, o prazo da premiação será suspenso e voltará a fluir integralmente após a divulgação do resultado da investigação.</p>
    </S.WarningContainer>
  </S.CampaignFlowSectionContainer>
}
