import styled from "styled-components"

export const Container = styled.li``

export const Label = styled.label`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Input = styled.input`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`