import styled from "styled-components";

export const Container = styled.div.attrs(() => {
  return {
    className: 'js-premmiar-container'
  }
})`
  align-items: center;
  display: none;
  inset: 0;
  justify-content: center;
  position: fixed;
  z-index: 99999;

  &:before {
    background-color: rgba(0, 0, 0, .7);
    content: "";
    display: block;
    inset: 0;
    position: absolute;
    z-index: -1;
  }

  iframe {
    height: 500px;
    width: 600px;
  }

  &.open {
    display: flex;
  }
`