import * as S from './style'

export default function AwardTable ({ caption, areaManagerWinners, coordinatorWinners, executiveWinners, managerWinners, promoterWinners }) {
  return <S.AwardTable>
    <caption>Canal: {caption}</caption>
    <thead>
      <tr>
        <th>Cargo:</th>
        <th>Ganhadores:</th>
        <th>Pontos:</th>
      </tr>
    </thead>

      {caption !== 'MERCHANDISING'
      ? (<tbody>
        {
          executiveWinners && (
            <tr>
              <td>EXECUTIVOS + COORDENADORES</td>
              <td>{executiveWinners}</td>
              <td>1.000</td>
            </tr>
          )
        }
          {
            areaManagerWinners && (
              <tr>
                <td>GERENTES DE ÁREA</td>
                <td>{areaManagerWinners}</td>
                <td>1.000</td>
              </tr>
            )
          }
          </tbody>)
      : (<tbody>
          {
            managerWinners && (
              <tr>
                <td>GERENTES</td>
                <td>{managerWinners}</td>
                <td>1.000</td>
              </tr>
            )
          }
          {
            coordinatorWinners && (
              <tr>
                <td>COORDENADORES</td>
                <td>{coordinatorWinners}</td>
                <td>450</td>
              </tr>
            )
          }
          {
            promoterWinners && (
              <tr>
                <td>PROMOTORES</td>
                <td>{promoterWinners}</td>
                <td>450</td>
              </tr>
            )
          }
        </tbody>)
      }
  </S.AwardTable>
}
