import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useMenu } from '../../../contexts/DesktopMenuContext';
import { useClickOutside } from '../../../hooks/useClickOutside';
import api from '../../../services/api';
import HexagonComponent from '../../Hexagon';
import Premmiar from '../../Premmiar';
import MenuButton from './MenuButton';
import * as S from './style';

function MobileMenu() {
    const [catalogUrl, setCatalogUrl] = useState()
    const [openMenu, setOpenMenu] = useState(false)
    const [premmiar, setPremmiar] = useState(false)
    const [wrapperRef] = useClickOutside(function () { setOpenMenu(false) });
    const { avatar, user, code, userCan } = useAuth();
    const { menuItens } = useMenu();
    const navigate = useNavigate();

    const loadCatalog = useCallback(async () => {
        const response = await api.get('/premmiar')
        setCatalogUrl(response.data.catalogUrl)
    }, [])

    function handleLogout() {
        localStorage.clear();
        navigate("/")
    }

    const toggleMenu = useCallback((e) => {
        const href = e.target.getAttribute('href')
        if (!href) {
            e.preventDefault();
            e.stopPropagation();
        }
        setOpenMenu(!openMenu);
    }, [openMenu]);

    useEffect(() => {
        loadCatalog()
    }, [loadCatalog])

    return (
        <div className="header-actions">
            <S.MobileWrapper className="action-list" onClick={toggleMenu}>
                <div className="action-list-item-wrap">
                    <div className="action-list-item">
                        <div className="action-list-item-icon burger-icon inverted">
                            <div className="burger-icon-bar"></div>

                            <div className="burger-icon-bar"></div>

                            <div className="burger-icon-bar"></div>
                        </div>
                    </div>
                </div>
                <div  >
                    <nav id="navigation-widget-mobile" className={`navigation-widget navigation-widget-mobile sidebar left ${openMenu ? '' : 'hidden'}`} >
                        <div className="simplebar-wrapper" style={{ margin: "0px 0px -40px" }}>

                            <div className="simplebar-height-auto-observer-wrapper">
                                <div className="simplebar-height-auto-observer"></div>
                            </div>

                            <div className="simplebar-mask" onClick={toggleMenu} ref={wrapperRef}>
                                <div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
                                    <div className="simplebar-content-wrapper">
                                        <div className="simplebar-content" style={{ padding: "0px 0px 40px" }}>

                                            <div className="navigation-widget-close-button" onClick={toggleMenu}>
                                                <svg className="navigation-widget-close-button-icon icon-back-arrow">
                                                    <use href="#svg-back-arrow"></use>
                                                </svg>
                                            </div>

                                            <div className="navigation-widget-info-wrap">
                                                <div className="navigation-widget-info">
                                                    <Link className="user-avatar small no-outline" to="/perfil">
                                                        <div className="user-avatar-content">
                                                            <div className="hexagon-container">
                                                                <HexagonComponent src={avatar} alt="Avatar" />
                                                            </div>
                                                        </div>
                                                    </Link>

                                                    <p className="navigation-widget-info-title"><Link to="/perfil">{user.name}</Link></p>

                                                    <p className="navigation-widget-info-text">Bem vindo!</p>
                                                </div>

                                                <button
                                                    type="text"
                                                    onClick={handleLogout}
                                                    className="navigation-widget-info-button button small secondary"
                                                >
                                                    Sair
                                                </button>
                                            </div>

                                            <p className="navigation-widget-section-title">Seções</p>

                                            <ul className="menu">
                                                {menuItens && menuItens.map(m => <MenuButton {...m} />)}
                                                {userCan('access:gigantes-2022') && <S.Li className={`menu-item`}>
                                                    <a className="menu-item-link" href={`${process.env.REACT_APP_GIGATES2022}auth?code=${encodeURIComponent(code)}`}>
                                                        <div className="menu-item-link-icon">
                                                            <p>Gigantes da Execução 2022</p>
                                                        </div>
                                                    </a>
                                                </S.Li>}
                                                {userCan('access:premmiar-2022') && catalogUrl && <S.Li className={`menu-item`}>
                                                    <a className="menu-item-link" href={catalogUrl} target='_blank' rel="noreferrer">
                                                        <div className="menu-item-link-icon">
                                                            <p>Catalogo de premios</p>
                                                        </div>
                                                    </a>
                                                </S.Li>}
                                                {userCan('access:premmiar-2023') && <S.Li className={`menu-item`}>
                                                    <button className="menu-item-link" onClick={() => setPremmiar(true)}>
                                                        <div className="menu-item-link-icon">
                                                            <p>Catalogo de premios</p>
                                                        </div>
                                                    </button>
                                                </S.Li>}
                                            </ul>

                                            <p className="navigation-widget-section-title">Meu perfil</p>

                                            <Link className="navigation-widget-section-link" to="/perfil">Perfil</Link>

                                            <Link className="navigation-widget-section-link" to="/perfil/newsfeed">Notificações</Link>


                                            <p className="navigation-widget-section-title">Conta</p>


                                            <Link className="navigation-widget-section-link" to="/perfil/edit">Configurações Gerais</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="simplebar-placeholder" style={{ width: "auto", height: "270px" }}></div>
                        </div>
                        <div className="simplebar-track simplebar-vertical" style={{ visibility: "visible" }}>
                            <div className="simplebar-scrollbar" style={{ height: "33px", transform: "translate3d(0px, 0px, 0px)", display: "block" }}></div>
                        </div>
                    </nav>
                </div>
            </S.MobileWrapper>

            <Premmiar open={premmiar} wrapperRef={wrapperRef} />
        </div>
    )
}

export default MobileMenu;