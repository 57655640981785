import { useEffect, useState, useMemo } from "react"

export default function useOnScreen(ref, data) {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  ), [])


  useEffect(() => {
    if (!data) return
    if (!ref.current) return
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [observer, ref, data])

  return isIntersecting
}
