import styled from "styled-components"

export const Container = styled.div`
  max-width: 1180px;
  margin: 0 auto;
`

export const Image = styled.img`
  display: block;
  max-width: 100%;
  width: auto;
`