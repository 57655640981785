import { useCallback } from "react"
import QuestionTitle from '../QuestionTitle'

function TextAreaQuestion(question) {
  const handleChange = useCallback((evt) => {
    question.onChange({
      questionId: question.id,
      answerId: parseInt(evt.target.value)
    })
  }, [question])
  return <li>
    <QuestionTitle>{question.title}</QuestionTitle>
    {question.description ?? <p>{question.description}</p>}
    <textarea onChange={handleChange}></textarea>
  </li>
}

export default TextAreaQuestion