import { useCallback, useState } from "react"
import QuestionTitle from '../QuestionTitle'
import * as S from './styled'

function RangeQuestion(question) {
  const [selected, setSelected] = useState()
  const handleChange = useCallback((value) => {
    question.onChange({
      questionId: question.id,
      response: parseInt(value + 1)
    })
    setSelected(value)
  }, [question])
  return <li>
    <QuestionTitle>{question.title}</QuestionTitle>
    {question.description ?? <p>{question.description}</p>}
    <S.Container>
      {[...Array(10).keys()].map(v => <li className={selected === v && 'active'}>
        <button type="button" onClick={() => handleChange(v)}>{v + 1}</button>
      </li>)}
    </S.Container>
  </li>
}

export default RangeQuestion