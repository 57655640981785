import * as S from './style'

import prizeHero from '../../../assets/img/regulamento/prize-hero.png'

export default function SectionPrize () {
  return <S.PrizeSectionContainer>
    <S.PrizeCardContainer>
      <div>
        <img src={prizeHero} alt="" />
      </div>

      <S.PrizeCardContent>
        <h1>O PRÊMIO</h1>

        <ul>
          <li>
            Os tributos são de responsabilidade do participante.
          </li>
          <li>
            Se sobre valor de Nota Fiscal, será considerado o faturamento líquido.
          </li>
          <li>
            O prêmio não poderá ser substituído a pedido do Participante.
          </li>
        </ul>
      </S.PrizeCardContent>
    </S.PrizeCardContainer>
  </S.PrizeSectionContainer>
}
