import styled from "styled-components"

export const Container = styled.ol`
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style: decimal;

  & > li {
    min-width: 100%;
    height: 100%;
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`