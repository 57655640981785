import leaf from '../../../assets/img/folhas-sem-tang.png'
import { useAuth } from '../../../contexts/AuthContext'
import RankingImage from '../RankingImage'
import * as S from './style'

const Row = ({ rowData, myRanking }) => {
  const { userCan } = useAuth()
  return <tr>
    <td><RankingImage position={rowData.position} /></td>
    <td>
      {myRanking || userCan('ranking-all:read') ?
        <S.HoldingName to={`/resultados/${rowData.user.id}?worksheet=${rowData.worksheetId}&step=${rowData.stepId}`}>{rowData.user.name}</S.HoldingName> :
        <S.HoldingNameNoLink>{rowData.user.name}</S.HoldingNameNoLink>
      }
    </td>
    <td>
      <S.StarCell>
        <p>
          <small>Escudos</small>
          <br />
          {rowData.points}
        </p>
        <S.Star src={leaf} alt="Estrela" />
      </S.StarCell>
    </td>
  </tr>
}


function RankingGeral({ ranking, myPosition, channel }) {
  return <div className="widget-box-content">
    <S.Pergunta>
      <h3>Sua posição</h3>

      <p>CLIQUE NO SEU NOME PARA VER SEUS RESULTADOS</p>
      <p>* A posição 9999 é referente os participantes que não deram ACEITE na Onda dentro do prazo!</p>

      <S.Widget>
        <S.TabelaMinhaPos>
          {myPosition && <table>
            {<Row rowData={myPosition} myRanking={true} />}
          </table>}
        </S.TabelaMinhaPos>

        <h3 style={{ width: '100%', marginTop: '32px' }}>Ranking Canal {channel}</h3>
        <S.Tabela>
          <table>
            {ranking && ranking.map(r => <Row rowData={r} />)}
          </table>
        </S.Tabela>
      </S.Widget>
    </S.Pergunta>
  </div>
}

export default RankingGeral;
