import Template from '../../template'
import * as S from './styled'

export default function Reconhecimento() {
  return <Template showBanner={false}>
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide1.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide2.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide3.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide4.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide5.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide6.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide7.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide8.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide9.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide10.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide11.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide12.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide13.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide14.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide15.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide16.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide17.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide18.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide19.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide20.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide21.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide22.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide23.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide24.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide25.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide26.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide27.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide28.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide29.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide30.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide31.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide32.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide33.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide34.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide35.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide36.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide37.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide38.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide39.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide40.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide41.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide42.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide43.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide44.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide45.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide46.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide47.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide48.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
    <S.Image src="https://img.casadebraganca.com/gigantes-2023/vencedores-etapa-4/v1/Slide49.JPG" alt="Reconhecimento_ONDA_2_V6_ampl_Page_01" />
  </Template>
}