import DashboardCard from "./DashboardCard"
import AccountStatBoxIcon from "./AccountStatBoxIcon"
import AccountStatBoxGraph from "./AccountStatBoxGraph"
import AccountStatBoxTitle from "./AccountStatBoxSubtitle"
import AccountStatBoxSubtitle from "./AccountStatBoxSubtitle"
import AccountStatBoxText from "./AccountStatBoxText"

function AccountStatBox({ icon, title, children, subtitle, text }) {
  return <DashboardCard>
    {icon && <>
      <AccountStatBoxIcon>
        {icon.match(/^(data||https?):/) ?
          <img src={icon} alt="" /> :
          <svg className={`account-stat-box-icon ${icon}`}><use xlinkHref='#svg-friend'></use></svg>
        }
      </AccountStatBoxIcon>

      <AccountStatBoxTitle>
        {title}
      </AccountStatBoxTitle>
    </>}

    {!icon && <AccountStatBoxGraph>
      {children}
    </AccountStatBoxGraph>}

    <AccountStatBoxSubtitle>
      {subtitle}
    </AccountStatBoxSubtitle>

    <AccountStatBoxText dangerouslySetInnerHTML={{ __html: text }} />
  </DashboardCard>
}

export default AccountStatBox