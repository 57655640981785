import * as S from './style'

import brandsInline from '../../../../assets/img/regulamento/brands-inline.png'
import shield from '../../../../assets/img/regulamento/shield.png'

export default function Step ({ brands, description, header, title, text }) {
  return <S.StepContainer>
    <div>
      {header && (
        <h1>
          <span>Ranking</span>
          <br />
          dos escudos
        </h1>
      )}
    </div>

    <S.StepContent>
      <div>
        <div>
          <img src={shield} alt="" />
        </div>

        <div>
          <p>{title}</p>

          <p>{description}</p>

          {text && <p>{text}</p>}
        </div>
      </div>

      <div>
        {brands && <img src={brandsInline} alt="" />}
      </div>
    </S.StepContent>
  </S.StepContainer>
}