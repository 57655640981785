import BannerTitleComponent from '../components/BannerTitleHome'
import FooterComponent from '../components/FooterComponent'
import HeaderComponent from '../components/Header'
import SidebarComponent from '../components/SidebarComponent'
import { useAuth } from '../contexts/AuthContext'
import { useMenu } from '../contexts/DesktopMenuContext'
import * as S from './styled'

export default function Template({ children, showBanner = true }) {
    const { avatar, user } = useAuth();
    const { open } = useMenu();
    return (
        <>
            <SidebarComponent />
            <HeaderComponent />
            <S.Container className={`content-grid ${open ? "" : "with-menu-desktop"}`}>
                {showBanner && <BannerTitleComponent
                    avatar={avatar}
                    username={user.name}
                    position={user.position}
                    stars={user.points}
                />}
                {children}
            </S.Container>
            <FooterComponent />
        </>
    )
}